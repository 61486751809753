
import ameli from 'Api/ameli';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Absence from './Absence';

export interface AbsenceGetParams {
    id?: number,
    employee_id?: number,
    start?: string,
    pend?: string
}



export function useAbsences(params: AbsenceGetParams) {
    return useQuery({
        queryKey: ['absences', params],
        queryFn: () => ameli.get<Absence[]>(`absences`, { params }).then(res => res.data),
        enabled: params != null
    })
}

export function useAbsence(id:number) {
    return useQuery({
        queryKey: ['absences', id],
        queryFn: () => ameli.get<Absence[]>(`absences/${id}`).then(res => res.data),
        enabled: Boolean(id)
    })
}


export function useAbsenceMutation<T>(mutationFn) {

    const queryClient = useQueryClient()

    return useMutation<unknown, Error, T>({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['absences'] })
        }
    })
}

export const useAbsenceUpdate = () => {
    return useAbsenceMutation<Event>(
        (data: Absence) => ameli.patch(`absences/${data.id}`, data)
    )
}

export const useAbsenceCreate = () => {
    return useAbsenceMutation<any>(
        (data: Absence) => ameli.post('absences', data)
    )
}

export const useAbsenceDelete = () => {
    return useAbsenceMutation<number>(
        (id: number) => ameli.delete(`absences/${id}`)
    )
}
