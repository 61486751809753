import ActionIcons from "app/Actions/ActionIcons"
import { H6 } from "./labels/Label"
import Row from "./Row"
import Spacer from "./Spacer/Spacer"
import Action from "Common/Actions/Action"

type TitleViewProps = {
    title: string,
    leftActions?: Action[],
    rightActions?: Action[]
}

export default function TitleView({title, leftActions, rightActions}:TitleViewProps) {
    return (
        <Spacer size='medium'>
            <Row vCentered gap='small'>
                <ActionIcons actions={leftActions}/>
                <H6>{title}</H6>
                <ActionIcons actions={rightActions}/>
            </Row>
        </Spacer>
    )
}