import { usePerson } from "./PersonApi"
import { useMemo } from "react"
import ClickableOpacity from "components/Decorator/ClickableOpacity"
import { Tag } from "Tags/Tag"
import Person from "./Person"
import { createSearchParams, useNavigate } from "react-router-dom"


type PersonTagProps = {
    person: Person
}

export default function PersonTag({person}:PersonTagProps) {

    const navigate = useNavigate()

    const label = useMemo(() => {
        if (!person) {
            return 'Unbekannt'
        }
        if (person) {
            return `${person.first_name} ${person.last_name}`
        } 
    }, [person])

    const handleClick = () => {
        if(!person) {
            return
        }

        const destination = person.employee ? '/Employees' : 
            person.employee ? '/Customers' : 
            null
                
        navigate({
            pathname: destination,
            search: createSearchParams({
                id: String(person.id)
            }).toString()
        })
    }

    return (
        <ClickableOpacity onClick={handleClick}>
            <Tag text={label}/>
        </ClickableOpacity>
    )
}