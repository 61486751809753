import React, { useCallback, useContext, useMemo, useState } from "react";
import Filter from "./Filter";

type FilterContextValue<T> = {
    addFilter: (filter: Filter<T>) => void,
    removeFilter: (filter: Filter<T>) => void,
    filter: Filter<T>[]
}

const FilterContext = React.createContext<FilterContextValue<any>>({
    addFilter: (filter) => {},
    removeFilter: (filter) => {},
    filter:[]
})

function FilterProvider<T>({...props}) {
    const [filter, setFilter] = useState<Filter<T>[]>([])

    const addFilter = useCallback((value:Filter<T>) => {
        setFilter((previousFilter) => [...previousFilter, value])
    }, [setFilter])

    const removeFilter = useCallback((value:Filter<T>) => {
        setFilter((previousFilter) => previousFilter.filter(item => item !== value))
    }, [setFilter])

    const value = useMemo(() => ({
        addFilter,
        removeFilter,
        filter
    }), [addFilter, removeFilter, filter])

    return( 
        <FilterContext.Provider value={value} {...props}/>
    )
}

function useFilterContext<T>() {
    return useContext(FilterContext) as FilterContextValue<T>;
}

export { useFilterContext, FilterProvider }