import NoteList from "Notes/NoteList";
import useEmployeeNotes from '../Employees/Api/useEmployeeNotes';
import useModal from 'components/modal/useModal';
import Tile from "components/Tiles/Tile";
import Spacer from "components/Spacer/Spacer";
import EmployeeNoteModal from "./EmployeeNoteModal";


const EmployeeNoteTile = ({employee}) => {

    const { data } = useEmployeeNotes(employee?.person_id)
    const modal = useModal()

    return (
        <Tile
            title='Anmerkungen'
            actions={[
                {
                    icon: 'fa/FaPlus',
                    execute:() => modal.select({})
                }
            ]}
        >
            <EmployeeNoteModal {...modal} employeeId={employee?.person_id}/>
            <Spacer horizontal='medium'>
                <NoteList notes={data} onClick={modal.select} />
            </Spacer>
        </Tile>
    )
}

export default EmployeeNoteTile