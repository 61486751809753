import Modal, { IModalProps, ModalFooter } from "components/modal/Modal";
import ContactDetails from "./ContactDetails";
import useContactDetailsForm from "./useContactDetailsForm";
import ContactDetailsForm from "./ContactDetailsForm";
import Spacer from "components/Spacer/Spacer";
import PhoneListControl from "PhoneNumbers/PhoneListControl";
import { useContactDetailsUpdate } from "./ContactDetailsApi";
import FormUpdateButton from "Forms/FormUpdateButton";

interface Props extends IModalProps<ContactDetails> {}

export default function ContactDetailsModal({ selected, setVisible, ...props} : Props) {

    const form = useContactDetailsForm(selected)
    const isUpdate = 'id' in selected
    const update = useContactDetailsUpdate()
    const title = isUpdate ? 'Kontaktdetails bearbeiten' : 'Kontaktdetails erfassen'

    return (
        <Modal 
            title={title}
            selected={selected}
            setVisible={setVisible}
            {...props}
        >
            <Spacer size='medium'>
                <ContactDetailsForm form={form}/>
                <PhoneListControl contactDetailsId={selected?.id} />
                <ModalFooter>
                    <FormUpdateButton
                        visible={isUpdate}
                        form={form}
                        onClick={() => update.mutate(form.data, { onSettled: () => setVisible(false) })}
                    />
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}