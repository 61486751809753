import Tile from "components/Tiles/Tile";
import { useContext, useMemo } from "react";
import { EventGetParams, useEvents } from "./EventApi";
import moment from "moment";
import ListView from "components/ListView/ListView";
import Event from "./Event";
import EventUtility from "./EventUtility";
import ClickableOpacity from "components/Decorator/ClickableOpacity";
import Padding from "components/Padding";
import Row from "components/Row";
import { H6, LeadingText } from "components/labels/Label";
import { ModalContext } from "components/modal/ModalContext";
import Column from "components/Column";
import PersonTag from "Persons/PersonLink";
import TagContainer from "Tags/TagContainer";
import ScrollView from "components/ScrollView";


export default function SubstitutedEventsTile() {

    const params:EventGetParams = useMemo(() => ({
        periodStart: moment().format('YYYY-MM-DD HH:mm'),
        substituted: true
    }), [])

    const events = useEvents(params)

    const groupedEvents:{[key:string]: Event[]} = useMemo(() => {
        if(!events.data) {
            return {}
        }

        return events.data.reduce((groups, event) => {
            if(!groups[event.date]) {
                groups[event.date] = []
            }
            groups[event.date].push(event)
            return groups
        }, {})
    }, [events.data])

    if(Object.values(groupedEvents).length === 0) {
        return null
    }

    return (
        <Tile title='Vertretungen'>
            <ScrollView>
                <Padding horizontal='none'>
                    <Column gap='medium'>
                    { Object.entries(groupedEvents).map(([date, events], index) => (
                        <GroupedList
                            key={index}
                            date={date}
                            events={events}
                        />
                    ))}
                    </Column>       
                </Padding>
            </ScrollView>
        </Tile>
    )
}

type GroupedListProps = {
    date: string,
    events: Event[]
}

const GroupedList = ({date, events}: GroupedListProps) => {
    return (
        <div>
            <Padding vertical='none'>
                <H6>{moment(date).format('dd. DD.MM')}</H6>
            <ListView
                data={events}
                listItem={(event) => <SubstituteListItem event={event} />}
                Separator={() => null}
            />
            </Padding>
        </div>
    )
}

type SubtituteListItemProps = {
    event:Event
}

const SubstituteListItem = ({ event }: SubtituteListItemProps) => {

    const { eventModal } = useContext(ModalContext)

    const periodString = useMemo(() => {
        return EventUtility.getPeriodLabel(event, 'HH:mm')
    }, [event])

    return (
        <ClickableOpacity onClick={() => eventModal.select(event)}>
            <Padding>
                <Row hGap='medium'>
                    <LeadingText>{periodString}</LeadingText>
                    <TagContainer>
                        <PersonTag person={event.employee} />
                        <PersonTag person={event.customer} />
                    </TagContainer>
                </Row>
            </Padding>
        </ClickableOpacity>
    )
}