import Modal, { ModalFooter } from "components/modal/Modal";
import ObjectForm, { IModelFormOptions } from "../Objects/ObjectForm";
import Spacer from "components/Spacer/Spacer";
import { useMemo } from "react";
import ActionButtons from "app/Actions/ActionButtons";
import { Actions } from "Common/Actions/Action";
import { IModelInstance } from "Common/Models/useModelInstance";

export interface IModelInstanceModalOptions {
    formOptions?: IModelFormOptions,
    actions?: Actions
}

interface IModelInstanceModalProps<T> extends IModelInstanceModalOptions {
    instance: IModelInstance<T>,
    visible: boolean,
    setVisible: (visible:boolean) => any,

}

export default function ModelInstanceModal<T>({ actions, instance, visible, setVisible, formOptions }: IModelInstanceModalProps<T>) {

    const title = useMemo(() => {
        const actionLabel = instance.isUpdate ? 'bearbeiten' : 'erfassen'
        return `${instance.model.labels[0]} ${actionLabel}`
    }, [instance.model, instance.isUpdate])

    const modelActionOptions = { onSuccess: () => {
        setVisible(false)
    } }

    const finalActions = useMemo(() => {
        return actions || instance.actions
    }, [instance, actions])

    return (
        <Modal
            visible={visible}
            setVisible={setVisible}
            select={instance.setValue}
            selected={instance.value}
            title={title}
        >
            <Spacer size='medium'>
                <ObjectForm object={instance} {...formOptions}/>
                <ModalFooter>
                    <ActionButtons actions={finalActions} options={modelActionOptions}/>
                </ModalFooter>
            </Spacer>

        </Modal>
    )
}