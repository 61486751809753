import Label from "components/labels/Label";
import Spacer from "components/Spacer/Spacer";
import Tile from "components/Tiles/Tile";
import moment from "moment";
import { useMemo } from "react";
import { FaEuroSign } from "react-icons/fa";
import Customer from "./Customer";
import useGroupedBudgets from "Budgets/useGroupedBudgets";
import BudgetGroupTable from "Budgets/BudgetGroupTable";

interface Props {
    customer:Customer
}

const CustomerBudgetTile = ({customer}:Props) => {

    const params = useMemo(() => {
        return customer ? {
            owner_id: customer.person_id,
            period_end: moment().format('YYYY-MM-DD'),
            period_start: moment().subtract(1, 'month').format('YYYY-MM-DD')
        } : null
    },[customer])

    const budgetGroups = useGroupedBudgets(params)

    return (
        <Tile
            title='Budgets'
        >       
            {
                budgetGroups.data.length > 0 ?
                    <BudgetGroupTable budgets={budgetGroups.data} />
                :
                    <Spacer horizontal='medium'>
                        <Label subtle>Kein Budget vorhanden</Label>
                    </Spacer>
            }
        </Tile>
    )
}

export default CustomerBudgetTile