import Table from "components/table/table";
import Checkbox from "components/checkbox/Checkbox";
import YearSelect from "components/Time/YearSelect";
import moment from "moment";
import { useMemo } from "react";
import { useInput } from "../../../hooks/input";
import useCustomerBudgetColumns from "../../../Budgets/useBudgetColumns";
import Spacer from 'components/Spacer/Spacer';
import Row from "components/Row";
import Button from 'components/Button';
import FlexFill from "components/FlexFill";
import useModal from "components/modal/useModal";
import BudgetCreateModal from "Budgets/BudgetCreateModal";
import Tile from "components/Tiles/Tile";
import { useOutletContext } from "react-router-dom";
import { useCustomer, useCustomerUpdate } from "Customer/Api/CustomerApi";
import { useBudgets } from "Budgets/BudgetApi";
import { PersonScreenOutletContext } from "Persons/PersonScreen";
import { useClinicalPicture } from "ClinicalPictures/ClinicalPicturesApi";
import CustomerHeaderBar from "Customer/CustomerHeaderBar";
import BudgetModal from "Budgets/BudgetModal";
import Budget from "Budgets/Budget";

export default function CustomerBudgets() {

    const { person } = useOutletContext<PersonScreenOutletContext>()
    const customer = useCustomer(person?.id)
    const clinicalPicture = useClinicalPicture(person?.clinical_picture_id)
    const updateCustomer = useCustomerUpdate()
    const { columns } = useCustomerBudgetColumns();

    const { value:year, bind:bindYear } = useInput(moment().year());
    const params = useMemo(() => {
        return {
            owner_id: customer.data?.person_id || -1,
            period_start: year ? moment().year(year).startOf('year').format('YYYY-MM-DD') : undefined,
            period_end: year? moment().year(year).endOf('year').format('YYYY-MM-DD') : null,
        }
    }, [customer.data, year])
    const budgets = useBudgets(params);
    const modal = useModal<Budget>()
    const createModal = useModal()


    const onHasBlChange = (event) => {
        const selected = event.value ? true : false;
        updateCustomer.mutate({...customer.data, has_bl: selected})
    }


    function displayCreateModal() {
        createModal.select({
            owner_id: customer.data?.person_id,
            date: moment().format('YYYY-MM-DD'),
            care_level: clinicalPicture.data?.care_level || null
        });
    }   

    
    return (
        <>
            <CustomerHeaderBar />
            <BudgetCreateModal {...createModal}/>
            <BudgetModal {...modal}/>
            <Spacer size='medium' shrink>
                <Tile>
                    <Spacer horizontal='medium'>
                        <Row vCentered>
                            <YearSelect
                                label='Jahr'
                                value={year}
                                {...bindYear}>
                            </YearSelect>
                            <Button onClick={displayCreateModal}>
                                Budget erfassen
                            </Button>
                            <FlexFill />
                            <Checkbox
                                label='Betreuungsleistung jährlich erneuen'
                                value={customer.data?.has_bl}
                                onChange={onHasBlChange}>
                            </Checkbox>
                        </Row>
                    </Spacer>
                    <Table
                        columns={columns}
                        data={budgets.data || []}
                        onClick={modal.select}>
                    </Table>
                </Tile>        
            </Spacer>
            <BudgetCreateModal {...createModal}/>
        </>
    );
}