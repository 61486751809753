import { useMutation, useQueryClient } from "@tanstack/react-query";
import NoteApi from "./NoteApi";
import Note from "Notes/Note";

export default function useNoteUpdate() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [NoteApi.endpoint, 'update'],
        mutationFn: (data:Partial<Note>) => NoteApi.update(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [NoteApi.endpoint]})
        }
    })
}