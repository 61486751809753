import styled from 'styled-components';
import Label, { Text } from 'components/labels/Label';
import { ReactNode } from 'react';
import Theme from './../../Theming/Theme';
import ConditionalView from 'components/Decorator/ConditionalView';
import TagContainer from 'Tags/TagContainer';
import Action from 'Common/Actions/Action';
import ActionIcons from 'app/Actions/ActionIcons';
import { TagProps } from 'Tags/Tag';

const Container = styled.div`
    padding: ${Theme.vLarge} 0;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    min-height: 0;
    background-color: white;

    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    flex-shrink: 0;
`

const Header = styled.div`
    padding: 0 ${Theme.hMedium};
    padding-bottom: ${Theme.vMedium};
    display: flex;
    flex-direction: column;
`

const MainHeader = styled.div`
    display: flex;
    column-gap: ${props => props.theme.spacing.hSmall};
    align-items: center;
`

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 0px;
`

interface TileProps {
    title?: string,
    subtitle?: string,
    children?:ReactNode,
    tags?: TagProps[],
    leftActions?:Action[],
    actions?: Action[]
}

const Tile = ({
    title,
    subtitle,
    children,
    leftActions,
    actions,
    tags,
    ...props
}: TileProps) => {
    
    return (
        <Container {...props}>
            <ConditionalView condition={title || subtitle || actions || leftActions}>
                <Header>
                    <MainHeader>   
                        <ActionIcons actions={leftActions} />          
                        <TitleContainer>
                            <Text size='large' bold>{title}</Text>
                            <Label small subtle>{subtitle}</Label>
                        </TitleContainer>
                        <ActionIcons actions={actions}/>
                    </MainHeader>
                    <TagContainer tags={tags}/>
                </Header>
            </ConditionalView>
            <Content>
                { children }
            </Content>
        </Container>
    )
}


export default Tile 
export type { TileProps }