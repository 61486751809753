
import ameli from 'Api/ameli';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Event from './Event';


export interface EventGetParams {
    id?:number,
    customer_id?:number,
    employee_id?:number,
    person_id?:number
    periodStart?:string,
    periodEnd?:string,
    unconfirmed?:boolean,
    unaccounted?: boolean,
    substituted?: boolean,
    advertised?: boolean
}



export function useEvents(params: EventGetParams) {
    return useQuery({
        queryKey: ['events', params],
        queryFn: () => ameli.get<Event[]>(`events`, { params }).then(res => res.data),
        enabled: params != null
    })
}

export function useEvent(id) {
    return useQuery({
        queryKey: ['events', id],
        queryFn: () => ameli.get<Event[]>(`events/${id}`).then(res => res.data),
        enabled: Boolean(id)
    })
}


export function useEventMutation<T>(mutationFn) {

    const queryClient = useQueryClient()

    return useMutation<unknown, Error, T>({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['events'] })
        }
    })
}

export const useEventUpdate = (params) => {
    return useEventMutation<Event>(
        (data: Event) => ameli.patch(`events/${data.id}`, data, {params})
    )
}

export const useEventCreate = (params) => {
    return useEventMutation<any>(
        (data) => ameli.post('events', data, { params })
    )
}

export const useEventDelete = () => {
    return useEventMutation<number>(
        (id: number) => ameli.delete(`events/${id}`)
    )
}
