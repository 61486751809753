import styled from 'styled-components'
import { Size } from 'Theming/Theme'

type SpacerProps = {
    size?:Size,
    horizontal?:Size,
    vertical?:Size,
    top?:Size,
    bottom?:Size,
    left?:Size,
    right?:Size,
    children?:React.ReactNode
}

const Spacer = styled.div<SpacerProps>`
    display: flex;
    flex-direction: column;
    padding: ${props => {
        return props.theme.getSpacing(
            props.top || props.vertical || props.size || '0px',
            props.right || props.horizontal || props.size || '0px',
            props.bottom || props.vertical || props.size || '0px',
            props.left || props.horizontal || props.size || '0px',
        )
    }};
    flex-basis: auto;
    min-height: ${props => props.shrink ? 0 : 'auto'};
`

export default Spacer;
