import Table from 'components/table/table';
import { useInput } from 'hooks/input';
import moment from 'moment';
import Spacer from 'components/Spacer/Spacer';
import Row from 'components/Row';
import { useState, useMemo, useContext } from 'react';
import Tile from 'components/Tiles/Tile';
import { ModalContext } from 'components/modal/ModalContext';
import { useOutletContext } from 'react-router-dom';
import { useAbsenceTableColumns } from 'Absences/AbsenceTableColumns';
import TextInput from 'components/input/TextInput';
import EmployeeHeaderBar from 'Employees/EmployeeHeaderBar';
import YearSelect from 'components/Time/YearSelect';
import { PersonOutletContextType } from 'Persons/PersonOutlet';
import { useAbsences } from 'Absences/AbsenceApi';
import { FaPlus } from 'react-icons/fa';

export default function AbsenceView() {

    const { person } = useOutletContext<PersonOutletContextType>()
    const [year, setYear] = useState(moment().year())
    const { value: filter, bind: bindFilter } = useInput("");
    const { columns } = useAbsenceTableColumns(["Mitarbeiter"]);

    const absenceParams = useMemo(() => {
        return { 
            employee_id: person.id, 
            start: year ? moment().year(year).startOf('year').format('YYYY-MM-DD') : undefined,
            end: year ? moment().year(year).endOf('year').format('YYYY-MM-DD') : undefined
        }
    }, [year, person])

    const { data: absences } = useAbsences(absenceParams)
    const { absenceModal } = useContext(ModalContext)

    const showCreateModal = () => {
        var today = moment().format('YYYY-MM-DD')
        var data = { 
            'employee_id': person.id,
            'begin_date': today,
            'request_date': today,
            'end_date': today
        }
        absenceModal.select(data)
    }

    return (
        <>
            <EmployeeHeaderBar person={person}/>
            <Spacer size='medium' shrink>
                <Tile
                    title='Abwesenheiten'
                    actions={[
                        {
                            icon: 'fa/FaPlus',
                            execute: showCreateModal
                        }
                    ]}
                >
                    <Spacer horizontal='medium' grow>
                        <Row vCentered>
                            <TextInput size={20} label='Filtern' type='text' {...bindFilter} />
                            <YearSelect label='Jahr' value={year} onChange={(event) => { setYear(event.value) }} />
                        </Row>
                    </Spacer>
                    <Table
                        columns={columns}
                        data={absences}
                        filter={filter}
                        onClick={(absence) => absenceModal.select(absence)}>
                    </Table>
                </Tile>
            </Spacer>
        </>
    )
}