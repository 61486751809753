
import ameli from 'Api/ameli';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ContactDetails from './ContactDetails';


export function useContactDetails(id:number) {
    return useQuery({
        queryKey: ['contact_details', id],
        queryFn: () => ameli.get<ContactDetails>(`contact_details/${id}`).then(res => res.data),
        enabled: Boolean(id)
    })
}

export function useContactDetailsMutation<T>(mutationFn) {

    const queryClient = useQueryClient()

    return useMutation<unknown, Error, T>({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['contact_details'] })
        }
    })
}

export const useContactDetailsUpdate = () => {
    return useContactDetailsMutation<ContactDetails>(
        (data: ContactDetails) => ameli.patch(`contact_details/${data.id}`, data)
    )
}

export const useContactDetailsCreate = () => {
    return useContactDetailsMutation<any>(
        (data: ContactDetails) => ameli.post('contact_details', data)
    )
}

export const useContactDetailsDelete = () => {
    return useContactDetailsMutation<number>(
        (id: number) => ameli.delete(`contact_details/${id}`)
    )
}
