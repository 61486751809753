import ActionIcons from "app/Actions/ActionIcons";
import Action from "Common/Actions/Action";
import Row from "components/Row";
import Spacer from "components/Spacer/Spacer";
import PersonSearchField from "./PersonSearchField";

type PersonListHeaderProps = {
    actions?:Action[]
}

export default function PersonListHeader({ actions }:PersonListHeaderProps) {
    return (
        <Row gap='small' vCentered>
            <PersonSearchField/>
            <Spacer vertical='small'>
                <ActionIcons actions={actions}/>
            </Spacer>
        </Row>
    )
}