import Modal, { ModalFooter } from "components/modal/Modal";
import UserAppointmentForm from "./UserAppointmentForm";
import { useForm } from "components/form/form";
import SubmitButton from "Forms/SubmitButton";
import Spacer from "components/Spacer/Spacer";

export default function UserAppointmentModal({
    selected,
    visible,
    setVisible,
    dispatchAction
}) {

    const {
        formData,
        handleFormChange,
        formDataChanged,
        formDataValid
    } = useForm(selected);

    const isUpdate = formData && formData.id !== undefined;
    const title = isUpdate ? "Termin bearbeiten" : "Termin erstellen"
    
    return (
        <Modal
            minWidth="600px"
            visible={visible}
            setVisible={setVisible}
            title={title}>
            <Spacer size='medium'>
                <UserAppointmentForm
                    formData={formData}
                    handleFormChange={handleFormChange}
                />
                <ModalFooter>
                    <SubmitButton
                        visible={isUpdate}
                        disabled={!formDataChanged || !formDataValid}
                        onClick={() => dispatchAction('update', formData).then(() => setVisible(false))}>
                        Übernehmen
                    </SubmitButton>
                    <SubmitButton
                        visible={!isUpdate}
                        disabled={!formDataChanged || !formDataValid}
                        onClick={() => dispatchAction('create', formData).then(() => setVisible(false))}>
                        Erfassen
                    </SubmitButton>
                    <SubmitButton
                        visible={isUpdate}
                        onClick={() => dispatchAction('delete', formData.id).then(() => setVisible(false))}>
                        Löschen
                    </SubmitButton>
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}