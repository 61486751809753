import React from 'react';
import styled from 'styled-components';
import { SlideInProvider, SlideInProviderProps } from './SlideInContext';

const Container = styled.div`
    overflow: hidden;
    background-color: white;
    position: relative;
`

type SlideInViewProps = SlideInProviderProps & {
    children?: React.ReactNode
}

export default function SlideInView({ activePanel, setActivePanel, ...props}:SlideInViewProps) {
    return (
        <SlideInProvider activePanel={activePanel} setActivePanel={setActivePanel}>
            <Container {...props}/>
        </SlideInProvider>
    )
}