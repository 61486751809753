import { useCallback, useMemo } from "react"
import { useFilterContext } from "./FilterContext"

export default function useFilter<T>(data:T[]=[]) {

    const { filter } = useFilterContext<T>()

    const evaluateItem = useCallback((item) => {
        return !filter
            .filter(f => !f.disabled)
            .some(f => !f.evaluate(item))
    }, [filter])

    const filtered = useMemo(() => {
        return data.filter(evaluateItem)
    }, [evaluateItem, data])

    return filtered
}