import Modal, { IModalProps, ModalFooter } from "components/modal/Modal"
import Person from "./Person"
import usePersonForm from "./usePersonForm"
import PersonForm from "./PersonForm"
import Spacer from "components/Spacer/Spacer"
import { usePersonCreate, usePersonUpdate } from "./PersonApi"
import FormCreateButton from "Forms/FormCreateButton"
import FormUpdateButton from "Forms/FormUpdateButton"
import { MutateOptions } from "@tanstack/react-query"


interface Props extends IModalProps<Person> {
    mutateOptions?: MutateOptions<unknown, Error, Person>
}

const PersonModal = ({ selected, mutateOptions, ...modalProps}:Props) => {

    const isUpdate = selected && 'id' in selected
    const title = isUpdate ? "Person bearbeiten" : "Person erfassen"
    const form = usePersonForm(selected)
    const createPerson = usePersonCreate()
    const updatePerson = usePersonUpdate()

    const finalMutateOptions = {
        onSettled: () => {
            modalProps.setVisible(false)
        },
        ...mutateOptions
    }

    const handleCreate = () => {
        createPerson.mutate(form.data, finalMutateOptions)
    }

    const handleUpdate = () => {
        updatePerson.mutate(form.data, finalMutateOptions)
    }

    return (
        <Modal title={title} selected={selected} {...modalProps}>
            <Spacer size='medium'>
                <PersonForm form={form}/>
                <ModalFooter>
                    <FormCreateButton
                        form={form}
                        visible={!isUpdate}
                        onClick={handleCreate}
                        isPending={createPerson.isPending}
                    />
                    <FormUpdateButton
                        form={form}
                        visible={isUpdate}
                        onClick={handleUpdate}
                        isPending={updatePerson.isPending}
                    />
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}

export default PersonModal