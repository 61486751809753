import { useMutation, useQueryClient } from "@tanstack/react-query";
import NoteApi from "./NoteApi";

export default function useNoteDelete() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [NoteApi.endpoint, 'delete'],
        mutationFn: (id:number) => NoteApi.delete(id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [NoteApi.endpoint]})
        }
    })
}