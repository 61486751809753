import { useMutation, useQueryClient } from "@tanstack/react-query"
import EmployeeApi from "Employees/Api/EmployeeApi"
import NoteApi from "Notes/Api/NoteApi"
import Note from "Notes/Note"


export default function useEmployeeNoteCreate(employeeId:number) {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [NoteApi.endpoint, EmployeeApi.endpoint, employeeId, 'create'],
        mutationFn: (data: Partial<Note>) => EmployeeApi.createNote(employeeId, data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [NoteApi.endpoint] })
        }
    })
}