import styled from 'styled-components'
import { useSlideInContext } from './SlideInContext'
import TitleView from 'components/TitleView'
import { useMemo } from 'react'
import Action from 'Common/Actions/Action'
import Theme from 'Theming/Theme'

type SlideInPanelProps = {
    title: string,
    key: string
    children?: React.ReactNode
}

const Panel = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: ${props => props.theme.colors.white};
`

export default function SlideInPanel({ title, children }: SlideInPanelProps) {

    const { activePanel, setActivePanel } = useSlideInContext()

    const leftActions:Action[] = useMemo(() => ([
        {
            icon: 'fa/FaArrowLeft',
            execute: () => setActivePanel(null),
            color: Theme.colors.subtleText
        }
    ]), [setActivePanel])

    if (title !== activePanel ) {
        return null
    }

    return (
        <Panel>
            <TitleView title={title} leftActions={leftActions}/>
            { children }
        </Panel>
    )
}