import React from "react"
import { useContext } from "react"


type SlideInContextValue = {
    activePanel: string | null,
    setActivePanel: (string) => any
}

const SlideInContext = React.createContext<SlideInContextValue>({
    activePanel: null,
    setActivePanel: () => {}
})

export type SlideInProviderProps = {
    activePanel: string | null,
    setActivePanel: (string) => any,
    children?: React.ReactNode
}

const SlideInProvider = ({ activePanel, setActivePanel, ...props }:SlideInProviderProps) => {

    return (
        <SlideInContext.Provider value={{ activePanel, setActivePanel }} {...props}/>
    )
}

const useSlideInContext = () => {
    return useContext<SlideInContextValue>(SlideInContext)
}

export { SlideInContext, SlideInProvider, useSlideInContext }