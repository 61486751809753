export type Size = 'none' | 'small' | 'medium' | 'large' | string

const getVerticalSpacing = (size: Size = 'none') => {
    switch (size) {
        case 'none': return '0px'
        case 'tiny': return '0.25em'
        case 'small': return "0.35em"
        case 'medium': return "0.75em"
        case 'large': return "1em"
        default: return size
    }
}

const getHorizontalSpacing = (size: Size = 'none') => {
    switch (size) {
        case 'none': return '0px'
        case 'tiny': return '0.3em'
        case 'small': return "0.5em"
        case 'medium': return "1em"
        case 'large': return "1.5em"
        default: return size
    }
}

const getSpacing = (top: Size, right: Size, bottom: Size, left: Size) => {
    return `${getVerticalSpacing(top)} ${getHorizontalSpacing(right)} ${getVerticalSpacing(bottom)} ${getHorizontalSpacing(left)}`
}

const Theme = {
    primaryColor: '#ce0069',
    secondaryColor: '#7a143d',
    whiteColor: 'white',
    subtleColor: '#aaa',
    dangerColor: 'rgb(165 15 15)',
    warningColor: '#d37500',
    successColor: '#4B884D',
    textColor: '#444',
    subtleTextColor: '#898989',

    infoBackgroundColor: '#0968A0',
    infoColor: 'white',

    formActionColor: '#888',
    formActionBackground: 'white',

    vTiny: "0.15em",
    vSmall: "0.35em",
    vMedium: "0.75em",
    vLarge: "1em",

    hTiny: "0.25em",
    hSmall: "0.5em",
    hMedium: "1em",
    hLarge: "1.5em",

    colors: {
        white: '#fff',
        border: '#c4c4c4',
        text: '#333',
        subtleText: '#898989',
        primary: '#ce0069',
        secondary: '#7a143d',
        danger: 'rgb(165 15 15)',
        warning: '#d37500',
        success: '#4B884D',
    },

    spacing: {
        none: ["0px, 0px"],
        small: ["0.35em", "0.5em"],
        medium: ["0.75em", "1em"],
        large: ["1em", "1.5em"],

        vTiny: "0.15em",
        vSmall: "0.35em",
        vMedium: "0.75em",
        vLarge: "1em",

        hTiny: "0.25em",
        hSmall: "0.5em",
        hMedium: "1em",
        hLarge: "1.5em"
    },

    getHorizontalSpacing,
    getVerticalSpacing,
    getSpacing
}

export default Theme