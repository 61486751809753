import { AbsenceContext } from 'Absences/AbsenceContext'
import { EventContext } from 'Events/EventContext'
import EventUtility from 'Events/EventUtility'
import { dateInPeriod } from 'Time/periodUtility'
import ConditionalView from 'components/Decorator/ConditionalView'
import Spacer from 'components/Spacer/Spacer'
import Label from 'components/labels/Label'
import { useContext, useMemo } from 'react'
import styled from 'styled-components'

const ClickWrapper = styled.div`
    transition: 0.15s;
    cursor: zoom-in;
    &:hover {
        opacity: 0.7;
    }
`

const AbsenceBanner = styled.div`
    background-color: #b90000;
    height: 0.5em;
`

const renderEventCountLabel = (events) => {
    if(!events || events.length == 0) {
        return '0 Einsätze'
    }
    if(events.length == 1) {
        return '1 Einsatz'
    }
    return `${events.length} Einsätze`
}

const Weekday = ({ date, employeeId, onClick=()=>{} }) => {

    const { data: absences } = useContext(AbsenceContext)
    const { data:events } = useContext(EventContext)

    const relevantEvents = useMemo(() => {
        return events?.filter(event => (
            EventUtility.includesDate(event, date) &&
            EventUtility.displayForEmployee(event, employeeId)
        ))
    }, [events, employeeId, date])

    const relevantAbsences = useMemo(() => {
        return absences.filter(absence => absence?.employee_id === employeeId && dateInPeriod(date, absence.begin_date, absence.end_date))
    }, [absences, employeeId, date])

    const dangerLevel = useMemo(() => {
        if(relevantAbsences.length === 0) {
            return 0
        }
        const demand = relevantEvents
            ?.filter(event => event.confirmed == null)
            .reduce(
                (max, event) => {
                    const demandThreat = event?.demand == 0 ? event.demand : event?.demand || 100
                    return Math.max(demandThreat, max)
                },
                0
            )

        return demand ?? 100
    }, [relevantAbsences, relevantEvents])

    return (
        <ClickWrapper onClick={() => onClick({ date, employeeId })}>
            <ConditionalView condition={relevantAbsences.length > 0}>
                <AbsenceBanner />
            </ConditionalView>   
            <Spacer size='medium'>
                {
                    relevantEvents?.length > 0 && (
                        <Label 
                            small
                            danger={dangerLevel > 50}
                            warning={dangerLevel > 0}
                        >
                            {renderEventCountLabel(relevantEvents)}
                        </Label>
                    )
                }             
            </Spacer>
        </ClickWrapper>
    )
}

const EmployeeScheduleRow = ({ dates, employee, showEmpty, onClick=()=>{} }) => {

    const { data: absences } = useContext(AbsenceContext)
    const { data: events } = useContext(EventContext)

    const relevantEvents = useMemo(() => {
        return events?.filter(event => (
            EventUtility.displayForEmployee(event, employee?.id)
        ))
    }, [events, employee])

    const relevantAbsences = useMemo(() => {
        return absences.filter(absence => absence?.employee_id === employee?.id)
    }, [absences, employee])

    if(!employee || (!showEmpty && relevantEvents?.length === 0 && relevantAbsences.length === 0)) {
        return null
    }


    return (
        <>
            <div>
                <Spacer size='medium'>
                    <Label>{`${employee.last_name}, ${employee.first_name}`}</Label>
                </Spacer>
            </div>
            {
                dates.map((date, index) => (
                    <Weekday 
                        onClick={onClick}
                        key={index} 
                        employeeId={employee.id} 
                        date={date} 
                    />
                ))
            }
        </>
    )
}

export default EmployeeScheduleRow