import Modal, { ModalProps } from "components/modal/NewModal";
import CareLevelFilter from "./CareLevelFilter";
import Spacer from "components/Spacer/Spacer";
import Column from "components/Column";
import VhFilter from "./VhFilter";

type CustomerFilterModalProps = ModalProps & {

}

export default function CustomerFilterModal({ ...props }: CustomerFilterModalProps) {

    return (
        <Modal title={"Filter anpassen"} {...props}>
            <Spacer size='medium'>
                <Column gap='medium'>
                    <CareLevelFilter label='Pflegegrad'/>
                    <VhFilter/>
                </Column>
            </Spacer>
        </Modal>
    )
}