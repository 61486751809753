import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import ameli from "Api/ameli"
import Person from "./Person"
import { ClinicalPicture } from "ClinicalPictures/ClinicalPicture"
import moment from "moment"
import { useMemo } from "react"
import { AxiosRequestConfig } from "axios"

export interface PersonGetParams {
    is_customer?:boolean,
    is_employee?:boolean,
    start_date?: string,
    end_date?: string,
    with_upcoming_birthday?: boolean,
    person_id?: number,
    limit?: number,
    page?: number,
    periodStart?: string,
    periodEnd?:string,
    latitude?:number,
    longitude?:number,
    providedServices?: number[],
    radius?:number,
    withAddress?:boolean,
    withTitle?:boolean,
    withContactDetails?:boolean
}

const parsePerson = (person: Person): Person => {
    return {
        ...person,
        deleteAt: person.deleteAt ? moment(person.deleteAt) : null
    }
}

const parsePersons = (persons:Person[]) : Person[] => {
    return persons.map(parsePerson)
}

export function usePersons(params:PersonGetParams) {

    const config:AxiosRequestConfig = useMemo(() => ({
        params: {
            withTitle: true,
            withAddress: true,
            withContactDetails: true,
            ...params,
        } 
    }), [params])

    return useQuery({
        queryKey: ['persons', config],
        queryFn: () => ameli.get<Person[]>('/persons', config).then(res => parsePersons(res.data))
    })
}

export function usePerson(personId:number) {

    return useQuery({
        queryKey: ['persons', personId],
        queryFn: () => ameli.get<Person>(`/persons/${personId}`).then(res => parsePerson(res.data)),
        enabled: Boolean(personId)
    })
}

export function usePersonMutation<T>(mutationFn) {

    const queryClient = useQueryClient()

    return useMutation<unknown, Error, T>({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['persons'] })
        }
    })
}

export const usePersonCreate = () => {
    return usePersonMutation<Person>(
        (data: Person) => ameli.post('persons', data)
    )
}


export const usePersonUpdate = () => {
    return usePersonMutation<Person>(
        (data:Person) => ameli.patch(`persons/${data.id}`, data)
    )
}

export const usePersonDelete = () => {
    return usePersonMutation<number>(
        (personId: number) => ameli.delete(`persons/${personId}`)
    )
}

export const useCreatePersonAddress = (personId, callback = () => { }) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (body) => ameli.post(`persons/${personId}/addresses`, body),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['persons'] })
            queryClient.invalidateQueries({ queryKey: ['addresses'] })
            callback()
        }
    })
}

export const useUpdatePersonAddress = (addressId, callback=()=>{}) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (body) => ameli.patch(`addresses/${addressId}`, body),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['persons'] })
            queryClient.invalidateQueries({ queryKey: ['addresses'] })
            callback()
        }
    })
}

export const useDeletePersonAddress = (addressId, callback = () => { }) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => ameli.delete(`addresses/${addressId}`),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['persons'] })
            queryClient.invalidateQueries({ queryKey: ['addresses'] })
            callback()
        }
    })
}


export const useCreatePersonContactDetails = (personId, callback = () => { }) => {
    const queryClient = useQueryClient()

    return useMutation<any, Error, any>({
        mutationFn: (body) => ameli.post(`persons/${personId}/contact_details`, body),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['persons'] })
            queryClient.invalidateQueries({ queryKey: ['contact_details'] })
            callback()
        }
    })
}

export const useUpdatePersonContactDetails = (contactDetailsId, callback = () => { }) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (body) => ameli.patch(`contact_details/${contactDetailsId}`, body),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['persons'] })
            queryClient.invalidateQueries({ queryKey: ['contact_details'] })
            callback()
        }
    })
}

export const useDeletePersonContactDetails = (contactDetailsId, callback = () => { }) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => ameli.delete(`contact_details/${contactDetailsId}`),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['persons'] })
            queryClient.invalidateQueries({ queryKey: ['contact_details'] })
            callback()
        }
    })
}

export function usePersonClinicalPicture(personId: number) {
    return useQuery({
        queryKey: ['persons', personId, 'clinical_pictures'],
        queryFn: () => ameli.get<ClinicalPicture>(`/persons/${personId}/clinical_picture`).then(res => res.data),
        enabled: Boolean(personId)
    })
}


export const useCreatePersonBankInformation = (personId:number) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (params:any) => ameli.post(`persons/${personId}/bank_information`, params),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['persons'] })
            queryClient.invalidateQueries({ queryKey: ['bank_informations'] })
        }
    })
}

export const usePersonsWithUpcomingBirthday = () => {
    return useQuery({
        queryKey: ['persons', 'withUpcomingBirthday'],
        queryFn: () => ameli.get<Person[]>('/persons/withUpcomingBirthday').then(res => res.data)
    })
}