import PersonSidebar from "../../../Persons/PersonSidebar";
import { useLocation, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from 'react';
import Page from "components/Page";
import Screen from "../Screen";
import ScreenLayout from "../ScreenLayout";
import usePersonUtility from "Persons/usePersonUtility";
import PersonOutlet from "Persons/PersonOutlet";
import PersonModal from "Persons/PersonModal";
import useModal from "components/modal/useModal";
import Person from "Persons/Person";
import { useCustomerContext } from "Customer/CustomerContext";
import { FilterProvider } from "components/Filter/FilterContext";
import CustomerFilterModal from "Customer/Filter/CustomerFilterModal";

export default function CustomerScreen() {

    const modal = useModal<Person>()
    const location = useLocation()
    const personUtility = usePersonUtility()
    const [searchParams, setSearchParams] = useSearchParams()
    const customers = useCustomerContext()
    const [filterModalVisible, setFilterModalvisible] = useState<boolean>()

    const actions = useMemo(() => {
        return [
            {
                icon: "fa/FaPlus",
                execute: () => modal.select({ customer: {} })
            },
            {
                icon: "fa/FaFilter",
                execute: () => setFilterModalvisible(true)
            }
        ]
    }, [modal.select, setFilterModalvisible])

    const person = useMemo(() => {
        const id = Number(searchParams.get('id'))
        if (!id || !customers.data) {
            return null
        }
        return customers.data.find(c => c.id === id)
    }, [searchParams, customers.data])


    useEffect(() => {
        if (!searchParams.get('id')) {
            const customer = customers.data?.find(c => personUtility.isActive(c))
            if (customer) {
                selectPerson(customer.id)
            }
        }
    }, [setSearchParams, customers, location.pathname, searchParams])

    const mutateOptions = {
        onSuccess: (response) => {
            selectPerson(response?.data?.id)
        }
    }

    const selectPerson = useCallback((id: number) => {
        setSearchParams((params) => ({
            id: String(id),
            ...params
        }))
    }, [setSearchParams])

    return (
        <FilterProvider<Person>>
            <Screen> 
                <PersonModal {...modal} mutateOptions={mutateOptions}/>
                <CustomerFilterModal setVisible={setFilterModalvisible} visible={filterModalVisible}/>
                <ScreenLayout.SideBar>
                    <PersonSidebar 
                        selected={person} 
                        select={(person) => selectPerson(person.id)}
                        persons={customers.data} 
                        actions={actions}                   
                    />   
                    <Page>         
                        <PersonOutlet person={person} />                     
                    </Page>        
                </ScreenLayout.SideBar>
            </Screen>
        </FilterProvider>
    );
}