import Modal, { IModalProps, ModalFooter } from "components/modal/Modal"
import Spacer from "components/Spacer/Spacer"
import ContactDetails from "ContactDetails/ContactDetails"
import ContactDetailsForm from "ContactDetails/ContactDetailsForm"
import useContactDetailsForm from "ContactDetails/useContactDetailsForm"
import FormUpdateButton from "Forms/FormUpdateButton"
import PhoneListControl from "PhoneNumbers/PhoneListControl"
import { useCreatePersonContactDetails, useDeletePersonContactDetails, useUpdatePersonContactDetails } from "./PersonApi"
import FormDeleteButton from "Forms/FormDeleteButton"
import FormCreateButton from "Forms/FormCreateButton"

interface Props extends IModalProps<ContactDetails> { 
    personId: number
}

export default function PersonContactDetailsModal({ personId, selected, setVisible, ...props }: Props) {

    const form = useContactDetailsForm(selected)
    const isUpdate = selected && 'id' in selected
    const title = isUpdate ? 'Kontaktdetails bearbeiten' : 'Kontaktdetails erfassen'
    const callback = () => setVisible(false)
    const create = useCreatePersonContactDetails(personId, callback)
    const update = useUpdatePersonContactDetails(selected?.id, callback)
    const erase = useDeletePersonContactDetails(selected?.id, callback)

    return (
        <Modal
            title={title}
            selected={selected}
            setVisible={setVisible}
            {...props}
        >
            <Spacer size='medium'>
                <ContactDetailsForm form={form} />
                <PhoneListControl contactDetailsId={selected?.id} />
                <ModalFooter>
                    <FormCreateButton
                        visible={!isUpdate}
                        form={form}
                        onClick={() => create.mutate(form.data)}
                    />
                    <FormUpdateButton
                        visible={isUpdate}
                        form={form}
                        onClick={() => update.mutate(form.data)}
                    />
                    <FormDeleteButton
                        visible={isUpdate}
                        form={form}
                        onClick={erase.mutate}
                    />
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}