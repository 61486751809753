import { useQuery } from '@tanstack/react-query';
import EmployeeApi from 'Employees/Api/EmployeeApi';
import NoteApi from 'Notes/Api/NoteApi';


export default function useEmployeeNotes(employeeId) {

    return useQuery({
        queryKey: [NoteApi.endpoint, EmployeeApi.endpoint, employeeId],
        queryFn: () => {
            return EmployeeApi.getNotes(employeeId)
        },
        enabled: !isNaN(employeeId)
    })
}