import EventListItem from "Events/EventListItem"
import Calendar from "components/Calendar/Calendar"
import useCalendar from "components/Calendar/useCalendar"
import useCalendarEntries from "components/Calendar/useCalendarEntries"
import Column from "components/Column"
import ListView from "components/ListView/ListView"
import Row from "components/Row"
import Spacer from "components/Spacer/Spacer"
import { ModalContext } from "components/modal/ModalContext"
import { useContext, useMemo } from "react"
import Person from "./Person"
import { useEvents } from "Events/EventApi"

interface Options {
    hideCustomer?:boolean,
    hideEmployee?:boolean
}

interface Props {
    person:Person,
    options?:Options
}

export default function PersonCalendar({ person, options } : Props) {

    const calendar = useCalendar()
    const { eventModal } = useContext(ModalContext)

    const params = useMemo(() => {
        if(!person) {
            return null
        }
        return {
            employee_id: person?.employee?.person_id,
            customer_id: person?.customer?.person_id,
            periodStart: calendar.monthStart.format('YYYY-MM-DD HH:mm'),
            periodEnd: calendar.monthEnd.format('YYYY-MM-DD HH:mm'),
        }
    }, [person, calendar.monthStart, calendar.monthEnd])

   
    const events = useEvents(params)
    const entries = useCalendarEntries({ events: events.data })

    const displayedEvents = useMemo(() => {
        if(!events.data || !calendar.selectedDate) {
            return []
        }
        const dateString = calendar.selectedDate.format('YYYY-MM-DD')
        return events.data.filter(e => e.date === dateString)
    }, [events.data, calendar.selectedDate])

    return (
        <Row gap='1em'>
            <Column>
                <Calendar 
                    {...calendar}
                    setDate={calendar.setDate}
                    entries={entries}
                />
            </Column>
            <Column grow>
                <Spacer size='medium'>
                    <ListView
                        data={displayedEvents} 
                        listItem={(event) => (
                            <EventListItem
                                periodFormat="HH:mm"
                                event={event}
                                onClick={() => eventModal.select(event)}    
                                hideCustomer={options?.hideCustomer}
                                hideEmployee={options?.hideEmployee}
                            />
                        )} 
                        Separator={() => null}    
                    />
                </Spacer>
            </Column>
        </Row>
    )
}