import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useSearchParams } from "react-router-dom";
import Screen from "app/Views/Screen";
import Page from "components/Page";
import ScreenLayout from "../ScreenLayout";
import usePersonUtility from "Persons/usePersonUtility";
import PersonOutlet from "Persons/PersonOutlet";
import useModal from "components/modal/useModal";
import Person from "Persons/Person";
import PersonModal from "Persons/PersonModal";
import PersonSidebar from "Persons/PersonSidebar";
import CentralizedView from 'components/CentralizedView';
import Label from 'components/labels/Label';
import { useEmployeeContext } from 'Employees/EmployeeContext';
import { FilterProvider } from 'components/Filter/FilterContext';

export default function EmployeeScreen() {
    const modal = useModal<Person>()
    const location = useLocation()
    const personUtility = usePersonUtility()
    const [searchParams, setSearchParams] = useSearchParams()
    const employees = useEmployeeContext()

    const actions = useMemo(() => {
        return [
            {
                icon: "fa/FaPlus",
                execute: () => modal.select({ employee: {} })
            }
        ]
    }, [modal.select])

    const person = useMemo(() => {
        const id = Number(searchParams.get('id'))
        if (!id || !employees.data) {
            return null
        }
        return employees.data.find(c => c.id === id)
    }, [searchParams, employees.data])

    useEffect(() => {
        if (!searchParams.get('id')) {
            const customer = employees.data?.find(c => personUtility.isActive(c))
            if (customer) {
                selectPerson(customer.id)
            }
        }
    }, [setSearchParams, employees, location.pathname, searchParams])

    const mutateOptions = {
        onSuccess: (response) => {
            selectPerson(response?.data?.id)
        }
    }

    const selectPerson = useCallback((id: number) => {
        setSearchParams((params) => ({
            id: String(id),
            ...params
        }))
    }, [setSearchParams])

    return (
        <FilterProvider<Person>>
            <Screen>
                <PersonModal {...modal} mutateOptions={mutateOptions}/>
                <ScreenLayout.SideBar>
                    <PersonSidebar
                        selected={person}
                        select={(person) => selectPerson(person.id)}
                        persons={employees.data}
                        actions={actions}
                    />
                    <Page>
                        {
                            person ? (
                                <PersonOutlet person={person} />
                            )
                                :
                                (
                                    <CentralizedView>
                                        <Label subtle>Kein Mitarbeiter ausgewählt</Label>
                                    </CentralizedView>
                                )
                        }
                    </Page>  
                </ScreenLayout.SideBar>
            </Screen>
        </FilterProvider>
    );
}