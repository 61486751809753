import PersonListItem from "Persons/PersonListItem";
import { useEffect, useMemo, useRef } from "react";
import SideBar from "Sidebar/SideBar";
import { TabPanel } from "components/tabs/Tabs";
import ScrollView from "components/ScrollView";
import ListView from "components/ListView/ListView";
import useTabs from "components/tabs/useTabs";
import TabRow from "components/tabs/TabRow";
import DefaultTab from "components/tabs/DefaultTab";
import { FlexGrow } from "components/Layout/Flex";
import Spacer from "components/Spacer/Spacer";
import usePersonUtility from "Persons/usePersonUtility";
import Person from "Persons/Person";
import PersonListHeader from "./PersonListHeader";
import Action from "Common/Actions/Action";
import useFilter from "components/Filter/useFilter";

interface Props {
    selected:Person,
    select?: (person:Person) => any
    persons: Person[],
    actions?:Action[]
}

export default function PersonSidebar({ actions, selected, select, persons}:Props) {
    
    const ref = useRef<HTMLElement>()
    const tabs = useTabs();
    const { isActive } = usePersonUtility()
    const filteredPersons = useFilter(persons)

    const activePersons = useMemo(() => {
        return filteredPersons ? filteredPersons?.filter(c => isActive(c)) : undefined
    }, [filteredPersons])

    const archivedPersons = useMemo(() => {
        return filteredPersons ? filteredPersons.filter(c => !isActive(c)) : undefined
    }, [filteredPersons])

    useEffect(() => {
        if (!filteredPersons || tabs.selected || !selected || !archivedPersons) {
            return
        } 
        tabs.select(archivedPersons.some(c => c.id == selected.id) ? 'Archiv' : 'Aktuell');      
    }, [selected, tabs.select, tabs.selected, archivedPersons, filteredPersons])


    return (
        <>
            <SideBar>
                <Spacer size='medium'>
                    <PersonListHeader actions={actions}/>
                </Spacer>
                <TabRow>
                    <FlexGrow>
                        <DefaultTab 
                            active={tabs.isSelected('Aktuell')} 
                            label={`Aktuell [${activePersons?.length || 0}]`}
                            onClick={() => tabs.select('Aktuell')}
                        />
                    </FlexGrow>
                    <FlexGrow>
                        <DefaultTab
                            active={tabs.isSelected('Archiv')}
                            label={`Archiv [${archivedPersons?.length || 0}]`}
                            onClick={() => tabs.select('Archiv')}
                        />
                    </FlexGrow>
                </TabRow>
                <ScrollView direction='rtl'>
                    <TabPanel active={tabs.isSelected('Aktuell')}>
                        <ListView 
                            data={activePersons} 
                            listItem={(person) => (
                                <PersonListItem 
                                    ref={selected?.id === person?.id ? ref : undefined}
                                    person={person}
                                    onClick={() => select(person)}
                                    marked={selected?.id === person?.id}
                                />
                            )}
                        />
                    </TabPanel>
                    <TabPanel active={tabs.isSelected('Archiv')} >
                        <ListView 
                            data={archivedPersons} 
                            listItem={(person) => (
                                <PersonListItem
                                    person={person}
                                    onClick={() => select(person)}
                                    marked={selected?.id === person?.id}
                                />
                            )}
                        />
                    </TabPanel>
                </ScrollView>
            </SideBar>
        </>
    );
}