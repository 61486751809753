import { useMutation, useQueryClient } from "@tanstack/react-query"
import NoteApi from "Notes/Api/NoteApi"
import Note from "Notes/Note"
import CustomerApi from "./CustomerApi"


export default function useCustomerNoteCreate(customerId:number) {
    const queryClient = useQueryClient()

    return useMutation({
        mutationKey: [NoteApi.endpoint, CustomerApi.endpoint, customerId, 'create'],
        mutationFn: (data: Partial<Note>) => CustomerApi.createNote(customerId, data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [NoteApi.endpoint] })
        }
    })
}