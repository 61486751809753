import Modal, { IModalProps, ModalFooter } from "components/modal/Modal";
import AbsenceForm from "./AbsenceForm";
import SubmitButton from "Forms/SubmitButton";
import Spacer from "components/Spacer/Spacer";
import useForm from "components/form/useForm";
import useAbsenceFields from "./useAbsenceFields";
import Row from "components/Row";
import VacationBookingPreview from "Vacations/Bookings/VacationBookingPreview";
import { useMemo, useState } from "react";
import Column from "components/Column";
import ConditionalView from "components/Decorator/ConditionalView";
import Absence from "./Absence";
import { useAbsenceCreate, useAbsenceDelete, useAbsenceUpdate } from "./AbsenceApi";
import useVacationBookingPreview from "Vacations/useVacationBookingPreview";

interface Props extends IModalProps<Absence> {}

type BookingPreview = {
    allowed?:boolean
}

export default function AbsenceModal({
    selected:absence, 
    visible, 
    setVisible,
    ...props
}:Props) {

    const fields = useAbsenceFields(absence)
    const form = useForm(fields)
    const isUpdate = 'id' in form.data;
    const title = isUpdate ? "Abwesenheit bearbeiten" : "Abwesenheit erfassen";
    const createAbsence = useAbsenceCreate()
    const updateAbsence = useAbsenceUpdate()
    const deleteAbsence = useAbsenceDelete()

    const bookingParams = useMemo(() => ({
        employeeId: form.data.employee_id,
        periodStart: form.data.begin_date,
        periodEnd: form.data.end_date,
        requestDate: form.data.request_date,
        excludedAbsences: form.data.id ? [form.data.id] : []
    }), [form.data])

    const bookingPreview = useVacationBookingPreview(bookingParams)

    const disableButtons = form.data.paid && bookingPreview?.data?.unbookedDays > 0

    const mutateOptions = {
        onSettled: () => setVisible(false)
    }

    const handleCreate = () => {
        createAbsence.mutate(form.data, mutateOptions)
    }

    const handleUpdate = () => {
        updateAbsence.mutate(form.data, mutateOptions)
    }

    const handleDelete = () => {
        deleteAbsence.mutate(form.data.id, mutateOptions)
    }

    return (
        <Modal
            selected={absence}
            visible={visible}
            setVisible={setVisible}
            title={title}
            {...props}
        >
            <Spacer size='medium'>
                <Row gap='1em'>
                    <Column grow>
                        <AbsenceForm form={form} />
                    </Column>
                    <ConditionalView condition={form.data.paid}>
                        <VacationBookingPreview preview={bookingPreview.data}/>
                    </ConditionalView>
                </Row>
                <ModalFooter>
                    {
                        isUpdate ? 
                            <>
                                <SubmitButton
                                    disabled={disableButtons || !form.fieldsChanged || !form.fieldsValid}
                                    onClick={() => handleUpdate()}
                                    >
                                    Übernehmen
                                </SubmitButton>
                                <SubmitButton
                                    onClick={() => handleDelete()}
                                >
                                    Löschen
                                </SubmitButton>
                            </>
                        :
                            <SubmitButton
                                disabled={disableButtons || !form.fieldsValid}
                                onClick={() => handleCreate()}
                            >
                                Erstellen
                            </SubmitButton>
                    }
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}