import SubmitButton from "Forms/SubmitButton"
import Modal, { ModalFooter } from "components/modal/Modal"
import Spacer from "components/Spacer/Spacer"
import { useMemo } from "react"
import { generatePositiveIntegerField, generateTextField } from "Forms/formFieldFactory"
import useForm from "Forms/useForm"
import TextInput from "components/input/TextInput"
import PhoneTypeSelect from "./PhoneTypeSelect"
import { FormGroup } from "components/form/FormComponents"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import ameli from "Api/ameli"
import FormUpdateButton from "Forms/FormUpdateButton"
import FormDeleteButton from "Forms/FormDeleteButton"
import FormCreateButton from "Forms/FormCreateButton"

const PhoneModal = ({selected:phoneNumber, visible, setVisible}) => {
    
    const isUpdate = 'id' in phoneNumber
    const title = isUpdate ? 'Telefonnummer bearbeiten' : 'Telefonnummer hinzufügen'

    const queryClient = useQueryClient()

    const fields = useMemo(() => ({
        'id': generatePositiveIntegerField(phoneNumber?.id, false),
        'contact_details_id': generatePositiveIntegerField(phoneNumber?.contact_details_id, true),
        'type_id': generatePositiveIntegerField(phoneNumber?.type_id, true),
        'number': generateTextField(phoneNumber?.number, true)
    }), [phoneNumber])

    const form = useForm(fields)

    const onMutationSuccess = () => {
        queryClient.invalidateQueries({
            queryKey: ['phone_numbers']
        })
        setVisible(false)
    }

    const create = useMutation({
        mutationFn: () => ameli.post('phone_numbers', form.data),
        onSuccess: onMutationSuccess
    })

    const update = useMutation({
        mutationFn: () => ameli.put('phone_numbers', form.data),
        onSuccess: onMutationSuccess
    })

    const remove = useMutation({
        mutationFn: () => ameli.delete(`phone_numbers/${form.data.id}`),
        onSuccess: onMutationSuccess
    })


    return (
        <Modal visible={visible} setVisible={setVisible} title={title}>
            <Spacer horizontal='medium' vertical='large'>
                <FormGroup>
                    <PhoneTypeSelect
                        label='Typ'
                        name='type_id'
                        value={form.data.type_id ?? ""}
                        onChange={(event) => form.setFieldValue('type_id', event.target.value)}
                    />
                    <TextInput
                        grow
                        label="Nummer"
                        name='number'
                        value={form.data.number ?? ""}
                        onChange={(event) => form.setFieldValue('number', event.target.value)}
                    />
                </FormGroup>
                <ModalFooter>
                    <FormUpdateButton visible={isUpdate} onClick={update.mutate} form={form} />
                    <FormDeleteButton visible={isUpdate} onClick={remove.mutate} form={form}/>
                    <FormCreateButton visible={!isUpdate} onClick={create.mutate} form={form} label='Hinzufügen'/>
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}

export default PhoneModal
