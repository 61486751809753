import { useFilterContext } from "components/Filter/FilterContext";
import { useEffect, useState } from "react";
import Person from "./Person";
import SearchField from "components/SearchField";
import Filter from "components/Filter/Filter";


export default function PersonSearchField() {
    const [query, setQuery] = useState("")
    const { addFilter, removeFilter } = useFilterContext<Person>()

    useEffect(() => {
        const adjustedQuery = query.toLowerCase()
        const filter:Filter<Person> = {
            evaluate: (person) => {
                return (
                    person.contactDetails?.phoneNumbers?.find(number => number.number.includes(adjustedQuery)) !== undefined ||
                    `${person.first_name} ${person.last_name}`.toLowerCase().includes(adjustedQuery) ||
                    String(person.id).includes(adjustedQuery)
                )
            }
        }

        addFilter(filter)
        return () => {
            removeFilter(filter)
        }
    }, [addFilter, removeFilter, query])

    return (
        <SearchField value={query} onChange={(event) => setQuery(event.target.value)} />
    )
}