import Spacer from "components/Spacer/Spacer";
import Screen from 'app/Views/Screen';
import { FlexShrink } from 'components/Layout/Flex';
import UnpaidBillsTile from 'Bills/UnpaidBillsTile';

export default function TodoScreen() {    

    return (
        <Screen>
            <Spacer size='medium' shrink>
                <FlexShrink>
                    <UnpaidBillsTile/>
                </FlexShrink>
            </Spacer>                        
        </Screen >
    );
}