import Column from "components/Column"
import Row from "components/Row"
import ScrollView from "components/ScrollView"
import Spacer from "components/Spacer/Spacer"
import { useOutletContext } from "react-router-dom"
import EmployeeHeaderBar from "./EmployeeHeaderBar"
import EmployeeTile from "./EmployeeTile"
import PersonTile from "Persons/PersonTile";
import { PersonOutletContextType } from "Persons/PersonOutlet";
import { useEmployee } from "./Api/EmployeeApi";
import PersonCalendar from "Persons/PersonCalendar"
import Tile from "components/Tiles/Tile"
import { useBankInformation } from "BankInformation/BankInformationApi"
import BankInformationTile from "BankInformation/BankInformationTile"
import ConditionalView from "components/Decorator/ConditionalView"
import AddComponentButton from "components/AddComponentButton"
import { FaPiggyBank } from "react-icons/fa"
import { useCreatePersonBankInformation } from "Persons/PersonApi"
import EmployeeNoteTile from "../EmployeeNotes/EmployeeNoteTile"
import VacationTile from "Vacations/VacationTile"
import EmployeeServiceTile from "EmployeeServices/EmployeeServiceTile"
import PersonLocationTile from "Persons/PersonLocationTile"

const EmployeeInfo = () => {

    const { person } = useOutletContext<PersonOutletContextType>()
    const employee = useEmployee(person?.id)
    const bankInformation = useBankInformation(person?.bank_information_id)
    const createBankInformation = useCreatePersonBankInformation(person?.id)

    return (
        <>
            <EmployeeHeaderBar person={person}/>
            <ScrollView>
                <Spacer size='medium'>
                    <Row gap='1em'>
                        <Column gap='0.75em'>
                            <PersonTile person={person} />
                            <EmployeeTile employee={employee.data}/>
                            <EmployeeServiceTile employeeId={person?.id} />
                            <EmployeeNoteTile employee={employee?.data} />
                            <BankInformationTile bankInformation={bankInformation.data}/>
                            <ConditionalView condition={bankInformation.data == null}>
                                <AddComponentButton
                                    text='Bankdaten hinzufügen'
                                    Icon={<FaPiggyBank/>}
                                    onClick={() => {
                                        createBankInformation.mutate({})
                                    }}
                                />
                            </ConditionalView>
                        </Column>
                        <Column grow gap='0.75em'>
                            <PersonLocationTile person={person}/>
                            <VacationTile employee={employee?.data} /> 
                            <Tile>
                                <Spacer size='small'>
                                    <PersonCalendar person={person} options={{ hideEmployee: true }} />
                                </Spacer>
                            </Tile>
                        </Column>
                    </Row>
                </Spacer>
            </ScrollView>
        </>
    )
}




export default EmployeeInfo