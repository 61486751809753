import NoteList from "Notes/NoteList"
import Tile from "components/Tiles/Tile"
import useModal from "components/modal/useModal"
import moment from "moment"
import useCustomerNotes from "../Customer/Api/useCustomerNotes"
import Spacer from "components/Spacer/Spacer"
import CustomerNoteModal from "./CustomerNoteModal"


const CustomerNoteTile = ({ customer }) => {

    const { data } = useCustomerNotes(customer?.person_id)
    const modal = useModal()

    return (
        <Tile
            title='Protokolle'
            actions={[
                {
                    icon: 'fa/FaPlus',
                    execute:() => modal.select({ customer_id: customer?.person_id })
                }
            ]}
        >
            <CustomerNoteModal {...modal} customerId={customer?.person_id} />
            <Spacer horizontal='medium'>
                <NoteList
                    notes={data?.filter(n => n.timestamp === null || moment().diff(moment(n.timeStamp), 'years') <= 0)}
                    placeholder='Keine Einträge vorhanden'
                    onClick={modal.select}
                />
            </Spacer>
        </Tile>
    )
}

export default CustomerNoteTile