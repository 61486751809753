import Address from "Addresses/Address";
import AddressForm from "Addresses/AddressForm";
import useAddressForm from "Addresses/useAddressForm";
import Modal, { IModalProps, ModalFooter } from "components/modal/Modal";
import Spacer from "components/Spacer/Spacer";
import FormCreateButton from "Forms/FormCreateButton";
import FormDeleteButton from "Forms/FormDeleteButton";
import FormUpdateButton from "Forms/FormUpdateButton";
import { useCreatePersonAddress, useDeletePersonAddress, useUpdatePersonAddress } from "./PersonApi";

interface Props extends IModalProps<Address>{
    personId:number
}

export default function PersonAddressModal({personId, selected, setVisible, ...props}:Props) {

    const isUpdate = selected ? 'id' in selected : false
    const title = isUpdate ? "Personenanschrift bearbeiten" : "Personenanschrift erfassen";
    const form = useAddressForm(selected)
    const mutationCallback = () => setVisible(false)
    const update = useUpdatePersonAddress(selected?.id, mutationCallback)
    const create = useCreatePersonAddress(personId, mutationCallback)
    const erase = useDeletePersonAddress(selected?.id, mutationCallback)

    return (
        <Modal 
            title={title} 
            selected={selected} 
            setVisible={setVisible} 
            {...props}
        >
            <Spacer size='medium'>
                <AddressForm form={form} />
                <ModalFooter>
                    <FormCreateButton 
                        form={form} 
                        visible={!isUpdate} 
                        onClick={() => create.mutate(form.data)}
                    />
                    <FormUpdateButton 
                        form={form} 
                        visible={isUpdate} 
                        onClick={() => update.mutate(form.data)} 
                    />
                    <FormDeleteButton 
                        form={form} 
                        visible={isUpdate} 
                        onClick={erase.mutate} 
                    />
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}