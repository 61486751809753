import Spacer from "components/Spacer/Spacer";
import Label from "components/labels/Label";
import { FaCircleNotch } from "react-icons/fa";
import styled from 'styled-components'

const List = styled.ul`
    width: 100%;
    direction: ltr;
    margin: 0;
    padding-left: 0;

    li {
        margin-left: 1em;
    }
`

const ItemWrapper = styled.div`

`

const LoadIconContainer = styled.div`
    font-size: 2em;
    height: 1em;
    padding: 0.5em;
    text-align: center;
`

const DefaultSeparator = styled.div`
    background-color: #e7e7e7;
    height: 1px;
`

export default function ListView({data, listItem, Separator=undefined, ...props}) {

    const renderContent = () => {
        if ('isLoading' in props && props.isLoading) {
            return (
                <LoadIconContainer key="load-icon">
                    <FaCircleNotch/>
                </LoadIconContainer>
            )
        }
        else if(!data) {
            return null
        }
        else if (data.length > 0) {
            return data.map((entry, index) => {
                return (
                    <ItemWrapper key={index}>
                        {listItem(entry)}
                        { 
                            index < data.length - 1 ?
                                Separator ? 
                                    <Separator/>
                                    : 
                                    <DefaultSeparator/>
                                : null
                                
                        }
                    </ItemWrapper>
                )
            });
        }
        else if (props.placeholder) {
            return (
                <Spacer vertical='small'>
                    <Label subtle key="placeholder">{props.placeholder}</Label>
                </Spacer>
            )
        }
        else {
            return null;
        }
    }

    return (
        <List>
            { renderContent() }
        </List>
    );
}