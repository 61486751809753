import Spacer from "components/Spacer/Spacer"
import { useState } from "react"
import styled from 'styled-components'

const Cell = styled.div`
    cursor: ${props => props.editable ? 'text' : 'pointer'};
    vertical-align: top;
`


const TableCell = ({
    row: { index},
    reportChange,
    renderLabel=() => null,
    renderInput=undefined
}) => {

    const [editing, setEditing] = useState(false)


    const onChange = (newValue) => {
        reportChange(index, newValue)
    }

    return (
        <Cell editable={renderInput !== undefined} onClick={() => setEditing(true)}>
            <Spacer size='medium'>
            {
                editing && renderInput ?
                    renderInput(onChange, setEditing)
                    :
                    renderLabel()
            }   
            </Spacer>
        </Cell>
       
    )
}

export default TableCell