import Spacer from "components/Spacer/Spacer"
import Tab from "./Tab"
import Label from "components/labels/Label"
import styled from 'styled-components'
import CentralizedView from "components/CentralizedView"

const StyledLabel = styled(Label)`
    font-weight: 800;
    color: ${props => props.active ? '#363636' : '#bbb'};
    margin-right: 0px;
`

const DefaultTab = ({active=false, label, ...props}) => {
    return (
        <Tab active={active} {...props}>
            <Spacer size='medium'>
                <CentralizedView>
                    <StyledLabel active={active}>{label}</StyledLabel>
                </CentralizedView>
            </Spacer>      
        </Tab>
    )
}

export default DefaultTab