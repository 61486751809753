
import ameli from 'Api/ameli';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import VacationRequest from './Requests/VacationRequest';

export interface VacationRequestGetParams {
    employee_id?: number
}



export function useVacationRequests(params: VacationRequestGetParams) {
    return useQuery({
        queryKey: ['vacations', 'requests', params],
        queryFn: () => ameli.get<VacationRequest[]>(`vacation_requests`, { params }).then(res => res.data),
        enabled: params != null
    })
}

export function useVacationRequest(id: number) {
    return useQuery({
        queryKey: ['vacations', 'requests', id],
        queryFn: () => ameli.get<VacationRequest>(`vacations/requests/${id}`).then(res => res.data),
        enabled: Boolean(id)
    })
}


export function useVacationRequestMutation<T>(mutationFn) {

    const queryClient = useQueryClient()

    return useMutation<unknown, Error, T>({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['vacations', 'requests'] })
        }
    })
}

export const useVacationRequestUpdate = () => {
    return useVacationRequestMutation<Event>(
        (data: VacationRequest) => ameli.put('vacations/requests', data)
    )
}

export const useVacationRequestCreate = () => {
    return useVacationRequestMutation<any>(
        (data: VacationRequest) => ameli.post('vacations/requests', data)
    )
}

export const useVacationRequestDelete = () => {
    return useVacationRequestMutation<number>(
        (id: number) => ameli.delete(`vacations/requests/${id}`)
    )
}
