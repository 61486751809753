
import Spacer from 'components/Spacer/Spacer';
import Modal, { IModalProps, ModalFooter } from './../components/modal/Modal';
import BankInformation from './BankInformation';
import useBankInformationForm from './useBankInformationForm';
import BankInformationForm from './BankInformationForm';
import { useBankInformationDelete, useBankInformationUpdate } from './BankInformationApi';
import FormUpdateButton from 'Forms/FormUpdateButton';
import FormDeleteButton from 'Forms/FormDeleteButton';

interface Props extends  IModalProps<BankInformation>{

}

export default function BankInformationModal({selected:bankInformation, ...props}:Props) {
    const isUpdate = bankInformation && 'bank_information_id' in bankInformation
    const title = isUpdate ? "Bankverbindung bearbeiten" : "Bankverbindung hinzufügen"
    const form = useBankInformationForm(bankInformation)
    const updateBankInformation = useBankInformationUpdate()
    const deleteBankInformation = useBankInformationDelete()

    const mutateOptions = {
        onSettled: () => props.setVisible(false)
    }

    const handleUpdate = () => {
        updateBankInformation.mutate(form.data, mutateOptions)
    }

    const handleDelete = () => {
        deleteBankInformation.mutate(form.data.bank_information_id, mutateOptions)
    }

    return (
        <Modal title={title} selected={bankInformation} {...props}>
            <Spacer size='medium'>
                <BankInformationForm form={form}/>
                <ModalFooter>
                    <FormUpdateButton
                        form={form}
                        visible={isUpdate}
                        onClick={handleUpdate}
                    />
                    <FormDeleteButton
                        form={form}
                        visible={isUpdate}
                        onClick={handleDelete}
                    />
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}