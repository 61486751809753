import Checkbox from "components/checkbox/Checkbox";
import Filter from "components/Filter/Filter";
import { useFilterContext } from "components/Filter/FilterContext";
import Person from "Persons/Person";
import { useEffect, useState } from "react";

export default function VhFilter({ label = 'Verhinderungspflege im Vorjahr', ...props}) {
    const { addFilter, removeFilter } = useFilterContext<Person>()
    const [checked, setChecked] = useState<boolean>(false)

    useEffect(() => {
        const filter:Filter<Person> = {
            evaluate: (person) => person.customer?.had_vh_last_year,
            disabled: !checked
        }

        addFilter(filter)

        return () => {
            removeFilter(filter)
        }
    }, [addFilter, removeFilter, checked])

    return (
        <Checkbox
            label={label}
            value={checked}
            onChange={({ value }) => setChecked(value)}
            {...props}
        />
    )
}