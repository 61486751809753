
import Dropdown from 'components/dropdown/dropdown';
import Filter from 'components/Filter/Filter';
import { useFilterContext } from 'components/Filter/FilterContext';
import Person from 'Persons/Person';
import { useEffect, useState } from 'react';

export default function CareLevelFilter({...props}) {

    const [selectedCareLevels, setSelectedCareLevels] = useState([])
    const { addFilter, removeFilter } = useFilterContext<Person>()

    const options = [
        { value: 0, label: 'Ohne' },
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
    ]

    const handleChange = (selection) => {
        setSelectedCareLevels(selection)
    }

    useEffect(() => {
        const careLevels = selectedCareLevels.map(c => c.value+1)
        const filter:Filter<Person> = {
            evaluate: (person) => {
                return careLevels.length === 0 || careLevels.includes(person.care_level)
            }
        }
        addFilter(filter)

        return () => {
            removeFilter(filter)
        }

    }, [selectedCareLevels, addFilter, removeFilter])

    return (
        <Dropdown 
            value={selectedCareLevels}
            options={options} 
            isMulti={true}
            placeholder="Alle Pflegegrade"
            {...props}   
            onChange={handleChange}  
        />
    )
}