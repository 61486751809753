import { Tag, TagProps } from "Tags/Tag";
import Assignment from "./Assignment";
import { lastNameFirstName } from "Persons/usePersonUtility";
import { useCallback, useContext, useMemo } from "react";
import { ModalContext } from "components/modal/ModalContext";
import { AssignmentMode } from "./AssignmentMode";
import { createSearchParams, useNavigate } from "react-router-dom";

export type AssignmentTagProps = {
    assignment:Assignment,
    mode: AssignmentMode
}

export default function AssignmentTag({ assignment, mode, ...props } : AssignmentTagProps) {

    const { assignmentModal } = useContext(ModalContext)
    const customerName = lastNameFirstName(assignment?.customer)
    const employeeName = lastNameFirstName(assignment?.employee)
    const navigate = useNavigate()

    const text = useMemo(() => {
        if(assignment?.advertise) {
            return "Suchanfrage"
        }
        switch(mode) {
            case 'customer':
                return employeeName
            case 'employee':
                return customerName
        }
    }, [mode, customerName, employeeName])

    const onClick = useCallback(() => {
        if (assignment?.advertise) {
            return
        }

        const destination = mode === 'customer' ? '/Employees' : '/Customers'
        const id = mode === 'customer' ? assignment.employee_id: assignment.customer_id
        
        navigate({
            pathname: destination,
            search: createSearchParams({
                id: String(id)
            }).toString()
        })
    }, [navigate, mode])

    return (
        <Tag
            text={text}
            onClick={onClick}
            actions={[
                {
                    icon: "fa/FaPen",
                    execute: () => assignmentModal.select(assignment)
                }
            ]}
            {...props}
        />
    )
}