import Button from "components/Button";
import React, { ReactNode, useContext } from "react";
import { FaTimes } from "react-icons/fa";
import styled, { keyframes } from 'styled-components'
import Label from "components/labels/Label";
import IconWrapper from '../Icons/IconWrapper';
import Spacer from "components/Spacer/Spacer";
import { ModalInternalContext, ModalInternalProvider } from "./ModalInternalContext";
import FlexFill from "components/FlexFill";
import { IModal } from "./useModal";

const FadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1 
    }
`

const SlideIn = keyframes`
  from {
        transform: translate(-50%, -50%);
        top: 30%;
    }
    to {
        transform: translate(-50%, -50%);
        top: 50% 
    }
`

const Wrapper = styled.div`
    z-index: 100;
    position:absolute;
    display: ${ props => props.visible ? 'block' : 'none'}
`

const Background = styled.div`
    animation-name: ${FadeIn};
    animation-duration: 0.1s;

    background-color: rgba(0, 0, 0, 0.63);
    position:fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`

const ModalContainer = styled.div`
    border-radius: 3px;
    animation-name: ${FadeIn}, ${SlideIn};
    animation-duration: 0.15s, 0.15s;
    animation-timing-function: ease-in, ease-out;
    box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.18);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 150px;
    min-width: ${ props => props.minWidth || '500px'};
    background-color: white;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
`

const TitleContainer = styled.div`
    flex-grow: 1;
`

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.25em;
    margin-top: 1em;

    button {
        min-width: 5rem;
    }
`

const IconContainer = styled(IconWrapper)`
`

export interface IModalProps<T> extends IModal<T> {
    title?:string,
    subTitle?:string,
    children?: ReactNode
}


export default function Modal<T>({ 
    selected, 
    select, 
    visible, 
    setVisible,
    title, 
    subTitle, 
    children
}: IModalProps<T>) {

    function hide() {
        setVisible(false);
    }

    if(!visible) {
        return null
    }

    return (
        <Wrapper visible={visible}>
            <ModalInternalProvider 
                setVisible={setVisible}
                visible={visible}
                selected={selected}
                select={select}
            >
                <Background onMouseDown={hide}>
                    <ModalContainer onMouseDown={(e) => e.stopPropagation()} onClick={e => e.stopPropagation()}>
                        <Spacer size='medium'>
                            <Header>
                                <TitleContainer>
                                    <Label ignoreCursor large bold>{title}</Label>
                                    {subTitle &&
                                        <Label medium>
                                            {subTitle}
                                        </Label>
                                    }
                                </TitleContainer>
                                <IconContainer onClick={hide}>
                                    <FaTimes size={20} />
                                </IconContainer>
                            </Header>
                        </Spacer>          
                        {children}
                    </ModalContainer>                               
                </Background>
            </ModalInternalProvider>
        </Wrapper>
    );
}

export const ModalFooter = ({onCancel=undefined, ...props}) => {
    const { setVisible } = useContext(ModalInternalContext)

    const handleCancel = () => {
        if(onCancel) {
            onCancel()
        }
        else {
            setVisible(false)
        }
    }

    return (
        <Footer>
            {props.children}
            <FlexFill/>
            <Button secondary onClick={handleCancel}>Zurück</Button>
        </Footer>
    )
}