import { IModal } from "components/modal/useModal"
import Modal, { ModalFooter } from "components/modal/Modal"
import Spacer from "components/Spacer/Spacer"
import FormUpdateButton from "Forms/FormUpdateButton"
import FormCreateButton from "Forms/FormCreateButton"
import Employee from "./Employee"
import useEmployeeForm from "./useEmployeeForm"
import { useEmployeeCreate, useEmployeeUpdate } from "./Api/EmployeeApi"
import EmployeeForm from "./EmployeeForm"

interface Props extends IModal<Employee> {
    isUpdate?: boolean
}

const EmployeeModal = ({ selected:employee, isUpdate = false, ...props }: Props) => {

    const title = isUpdate ? "Mitarbeiter bearbeiten" : "Mitarbeiter erfassen"
    const form = useEmployeeForm(employee)
    const createEmployee = useEmployeeCreate()
    const updateEmployee = useEmployeeUpdate()

    const mutateOptions = {
        onSettled: () => {
            props.setVisible(false)
        }
    }

    const handleCreate = () => {
        createEmployee.mutate(form.data, mutateOptions)
    }

    const handleUpdate = () => {
        updateEmployee.mutate(form.data, mutateOptions)
    }

    return (
        <Modal title={title} selected={employee} {...props}>
            <Spacer size='medium'>
                <EmployeeForm form={form}/>
                <ModalFooter>
                    <FormCreateButton form={form} visible={!isUpdate} onClick={handleCreate} />
                    <FormUpdateButton form={form} visible={isUpdate} onClick={handleUpdate} />
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}

export default EmployeeModal