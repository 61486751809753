
import ameli from 'Api/ameli';
import Customer from '../Customer';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoteApi from 'Notes/Api/NoteApi';
import Note from 'Notes/Note';

export interface CustomerGetParams {
    customer_id?: number,
    person_id?:number
}

const ENDPOINT = 'customers'

const CustomerApi = {
    endpoint: ENDPOINT,
    getNotes: (customerId: number) => {
        return ameli.get(`${ENDPOINT}/${customerId}/notes`).then(res => res.data)
    },
    createNote: (customerId: number, data: Partial<Note>) => {
        return ameli.post(`${ENDPOINT}/${customerId}/${NoteApi.endpoint}`, data).then(res => res.data)
    }
}

export default CustomerApi

export const useCustomer = (personId:number) => {

    return useQuery({
        queryKey: ['customers', personId],
        queryFn: () => ameli.get<Customer>(`customers/${personId}`).then(res => res.data),
        initialData: null,
        enabled: isNaN(personId) === false
    })
}

export function useCustomers(params:CustomerGetParams) {
    return useQuery({
        queryKey: ['customers', params],
        queryFn: () => ameli.get(`customers`, { params }).then(res => res.data),
        enabled: params != null,
        initialData: [],
        placeholderData: keepPreviousData
    })
}

export function useCustomerMutation<T>(mutationFn) {

    const queryClient = useQueryClient()

    return useMutation<unknown, Error, T>({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['customers'] })
            queryClient.invalidateQueries({ queryKey: ['persons']})
        }
    })
}

export const useCustomerUpdate = () => {
    return useCustomerMutation<Customer>(
        (data: Customer) => ameli.patch(`customers/${data?.person_id}`, data)
    )
}

export const useCustomerCreate = () => {
    return useCustomerMutation<Customer>(
        (data: Customer) => ameli.post('customers', data)
    )
}

export const useCustomerDelete = () => {
    return useCustomerMutation<number>(
        (customerId: number) => ameli.delete(`customers/${customerId}`)
    )
}
