import ameli from "Api/ameli"
import Note from "Notes/Note"


const ENDPOINT = 'notes'

const NoteApi = {
    endpoint: ENDPOINT,
    update: (data:Partial<Note>) => {
        return ameli.patch(`${ENDPOINT}/${data.id}`, data).then(res => res.data)
    },
    delete: (id:number) => {
        return ameli.delete(`${ENDPOINT}/${id}`).then(res => res.data)
    }
}

export default NoteApi