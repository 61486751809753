import { TitleLabel } from "components/labels/Label"
import styled from 'styled-components'
import Spacer from "components/Spacer/Spacer"
import Row from "components/Row"
import Button from "components/Button"
import { useNavigate, useOutletContext } from 'react-router-dom';
import DefaultNavigationTab from "components/tabs/DefaultNavigationTab"
import TabRow from "components/tabs/TabRow"
import usePersonUtility from "Persons/usePersonUtility"
import { usePersonDelete } from "Persons/PersonApi"
import Column from "components/Column"
import FlexFill from "components/FlexFill"
import { PersonOutletContextType } from "Persons/PersonOutlet"

const Header = styled.div`
    padding-top: 0.5em;
    background-color: white;
    display: flex;
    border-bottom: 1px solid rgb(219, 219, 219);
`

const CustomerHeaderBar = () => {

    const { person } = useOutletContext<PersonOutletContextType>()
    const navigate = useNavigate()
    const { isActive } = usePersonUtility()
    const deletePerson = usePersonDelete()
    const title = `${person?.title?.title_label} ${person?.first_name} ${person?.last_name}`
    const archived = !isActive(person)

    const deleteCustomer = () => {
        deletePerson.mutate(person?.id, {
            onSuccess: () => {
                navigate('../')
            }
        })
    }

    return (
        <>
            <Header>
                <Column>
                    <Spacer size='medium'>
                        <Row vCentered gap='medium'>
                            <TitleLabel>{title}</TitleLabel>          
                            <Row gap="4px">
                                {
                                    archived ?
                                        <Button secondary onClick={deleteCustomer}>Löschen</Button>
                                        :
                                        null
                                }
                            </Row>
                        </Row>
                    </Spacer>
                    <FlexFill/>
                    <TabRow>
                        <DefaultNavigationTab to='/Customers' label='Informationen' />
                        <DefaultNavigationTab to='/Customers/Protokolle' label='Protokolle' />
                        <DefaultNavigationTab to='/Customers/Budgets' label='Budgets' />
                        <DefaultNavigationTab to='/Customers/Einsaetze' label='Einsätze' />
                        <DefaultNavigationTab to='/Customers/Rechnungen' label='Rechnungen' />
                    </TabRow>
                </Column>
            </Header>
        </>
    )
}

export default CustomerHeaderBar