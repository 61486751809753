import { useMutation, UseMutationOptions, useQuery, useQueryClient } from "@tanstack/react-query"
import ameli from "Api/ameli"
import Address from "./Address"


export const useAddress = (id: number) => {
    return useQuery({
        queryKey: ['addresses', id],
        queryFn: () => ameli.get<Address>(`addresses/${id}`).then(res => res.data),
        enabled: Boolean(id)
    })
}

export function useAddresses(params = null) {
    return useQuery({
        queryKey: ['addresses', params],
        queryFn: () => ameli.get<Address[]>(`addresses`, { params }).then(res => res.data),
        enabled: params != null
    })
}

export function useAddressMutation(mutationFn) {

    const queryClient = useQueryClient()

    return useMutation({
        mutationFn,
        onSettled: (...props) => {
            queryClient.invalidateQueries({ queryKey: ['addresses']})
        }
    })
}

export const useCreateAddress = () => {
    return useAddressMutation((body) => ameli.post(`addresses`, body))
}

export const useUpdateAddress = () => {
    return useAddressMutation((body) => ameli.patch(`addresses/${body.id}`, body))
}

export const useDeleteAddress = () => {
    return useAddressMutation((id:number) => ameli.delete(`addresses/${id}`))
}