import ScrollView from "components/ScrollView"
import Tile from "components/Tiles/Tile"
import BirthdayList from "Persons/BirthdayList"
import { usePersonsWithUpcomingBirthday } from "Persons/PersonApi"


export default function UpcomingBirthdaysTile({...props}) {

    const { data, isPending } = usePersonsWithUpcomingBirthday()

    return (
        <Tile title='Geburtstage'>
            <ScrollView>
                <BirthdayList persons={data} isPending={isPending} />
            </ScrollView>
        </Tile>
    )
}