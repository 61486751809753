import { useCallback } from "react"
import useApiData from '../hooks/useApiData';
import useSignalingActions from "Signals/useSignalingActions";
import ameli from "Api/ameli";

const channel = ['OperationNotes']

const actions = {
    'create': data => ameli.post(`customers/${data.customer_id}/operation_notes`, data),
    'delete': data => ameli.delete(`notes/${data}`),
    'update': data => ameli.patch(`notes/${data.id}`, data)
}

const useOperationNoteActions = () => {
    return useSignalingActions(actions, channel)
}


const useOperationNotes = (params) => {
    const loadHandler = useCallback(() => new Promise((resolve, reject) => {
        return ameli.get(`customers/${params.customer_id}/operation_notes`, { params: params })
            .then(response => resolve(response.data))
    }), [params])


    const state = useApiData(loadHandler, channel)

    return state
}

export default useOperationNotes
export { useOperationNoteActions }