import styled from 'styled-components'
import Label from '../components/labels/Label';
import Spacer from 'components/Spacer/Spacer';
import { forwardRef, useMemo } from 'react';
import Person from './Person';
import { Tag } from 'Tags/Tag';
import ClickableOpacity from 'components/Decorator/ClickableOpacity';
import usePersonUtility from './usePersonUtility';
import Theme from 'Theming/Theme';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 4em;
    background-color: ${props => props.theme.colors.white};
`

const Content = styled.div`
    flex: 1;
`

const NameRow = styled.div`
    display: flex;
    justify-content: center;
    font-weight: ${props => props.marked ? 'bold' : 'normal'};
`

const NameLabel = styled(Label)`
    flex-grow: 1;
`

const IdLabel = styled(Label)`
`

const TagContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.3em;
    column-gap: 0.15em;
    row-gap: 0.15em;
`

const Marker = styled.div`
    width: 8px;
    background-color: ${props => props.visible ? '#ce0069' : 'transparent'};
`

interface Props {
    person:Person,
    onClick?: () => any,
    marked?:boolean
}

const PersonListItem = forwardRef(function PersonListItem({ person, onClick=undefined, marked=false, ...props }:Props, ref) {

    const personUtility = usePersonUtility()

    const tags = useMemo(() => {
        var result = []
        if (personUtility.flaggedForDeletion(person)) {
            result.push({ label: "Wird gelöscht", backgroundColor: Theme.dangerColor, color: Theme.whiteColor})
        }

        if(!personUtility.isActive(person)) {
            result.push({ label: "Archiviert"})
            return result
        }
        if(person.customer) {
            if(person.customer.state === false) {
                result.push({ label: 'Ehemalig' })
            }
            else if(person.customer.state === null) {
                result.push({ label: 'Interessent', backgroundColor: 'secondary', color: 'white' })
            }
            else {
                const isUnstaffed = personUtility.isUnstaffedCustomer(person)
                if (isUnstaffed) {
                    result.push({ label: 'Kein Mitarbeiter', backgroundColor: 'warning', color: 'white' })
                }
                if (person.customer.had_vh_last_year) {
                    result.push({ label: "VH" })
                }
                if (!isUnstaffed && person.customer.recentEvents === 0) {
                    result.push({ label: "Keine Einsätze", backgroundColor: 'danger', color: 'white' })
                }
            }
        }
        if(person.employee) {
            if(person.employee.state === null) {
                result.push({ label: 'Bewerber', backgroundColor: 'secondary', color: 'white' })
            }
            else if(person.employee.state === 0) {
                result.push({ label: 'Ehemalig' })
            }
            else {
                if (personUtility.isUnderusedEmployee(person)) {
                    result.push({ label: 'Keine Kunden', backgroundColor: 'warning', color:'white'})
                }
            }
        }
        return result
    }, [person])

    if(!person) {
        return <Label>Person nicht gefunden</Label>
    }

    return (
        <ClickableOpacity onClick={onClick} {...props}>
            <Container ref={ref}>
                <Content>
                    <Spacer size='medium'>
                        <NameRow marked={marked}>
                            <NameLabel>
                                {`${person.last_name}, ${person.first_name}`}
                            </NameLabel>
                            <IdLabel small>
                                {person.id}
                            </IdLabel>
                        </NameRow>
                        <TagContainer>
                            {tags.map(({ label, color, backgroundColor }, i) => (
                                <Tag 
                                    color={color} 
                                    backgroundColor={backgroundColor} 
                                    key={i}
                                    text={label}
                                />
                            ))}
                        </TagContainer>
                    </Spacer>
                </Content>
                <Marker visible={marked}/>
            </Container>
        </ClickableOpacity>
    );
})

export default PersonListItem