import Spacer from 'components/Spacer/Spacer';
import { useInput } from 'hooks/input';
import { FaClock } from 'react-icons/fa';
import moment from 'moment';
import Row from 'components/Row';
import TextInput from 'components/input/TextInput';
import Button from 'components/Button';
import { useMemo } from 'react';
import styled from 'styled-components';
import useModal from '../../../components/modal/useModal';
import Table from 'components/table/table';
import UserAppointmentModal from 'UserAppointments/UserAppointmentModal';
import useUserAppointmentColumns from 'UserAppointments/useUserAppointmentColumns';
import useUserAppointments, { useUserAppointmentActions } from 'UserAppointments/useUserAppointments';
import Screen from 'app/Views/Screen';
import Tile from 'components/Tiles/Tile';
import TodoSidebar from '../Home/TodoSidebar';
import UpcomingBirthdaysTile from 'Persons/Birthdays/UpcomingBirthdaysTile';
import UpcomingAbsencesTile from 'Absences/UpcomingAbsencesTile';
import SubstitutedEventsTile from 'Events/SubstitutedEventsTile';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    overflow: hidden;
`

const Tiles = styled.div`
    overflow: hidden;
    flex-grow: 1;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: auto 1fr;
    grid-template-areas: 
        "birthday events"
        "absences events"
        "substitutes events";
    column-gap: ${props => props.theme.spacing.vMedium};
    row-gap: ${props => props.theme.spacing.vMedium};
    padding: 1em;
`

const BirthdayArea = styled.div`
    grid-area: birthday;
    overflow: hidden;
`

const AbsenceArea = styled.div`
    grid-area: absences;
    overflow: hidden;
`

const SubstituteArea = styled.div`
    grid-area: substitutes;
    overflow: hidden;
`


const EventArea = styled.div`
    display: flex;
    grid-area: events;
    overflow: auto;
`

const UserAppointmentTile = () => {

    const { value: filter, bind: bindFilter } = useInput("");
    const columns = useUserAppointmentColumns()
    const modal = useModal()

    const params = useMemo(() => {
        return { user_id: 2 }
    }, [])

    const { data } = useUserAppointments(params)
    const userAppointmentActions = useUserAppointmentActions()

    return (
        <Tile
            title='Termine'
            Icon={FaClock}
        >
            <UserAppointmentModal {...modal} dispatchAction={userAppointmentActions.dispatch} />
            <Spacer size='medium' vSmall>
                <Row vCentered>
                    <TextInput size={20} label='Filtern' {...bindFilter} />
                    <Button onClick={() => modal.select({ user_id: 2, date: moment().format('YYYY-MM-DD') })}>Termin erstellen</Button>
                </Row>
            </Spacer>
            <Table
                data={data}
                filter={filter}
                columns={columns}
                onClick={modal.select}
            />
        </Tile>
    )
}

export default function HomeView() {

    return (
        <Screen>
            <Container>
                <Tiles>
                    <BirthdayArea>
                        <UpcomingBirthdaysTile />
                    </BirthdayArea>
                    <AbsenceArea>
                        <UpcomingAbsencesTile/>
                    </AbsenceArea>
                    <SubstituteArea>
                        <SubstitutedEventsTile/>
                    </SubstituteArea>
                    <EventArea>
                        <UserAppointmentTile />
                    </EventArea>
                </Tiles>
                <TodoSidebar />
            </Container>
        </Screen>
    );
}