import moment from "moment"
import Person from "./Person"
import { MapMarkerProps } from "components/map/MapMarker"

export function lastNameFirstName(person:Partial<Person>):string {
    if(!person || !person.last_name || !person.first_name) {
        return "Unbekannt"
    }
    return `${person.last_name}, ${person.first_name}`
}

export function generatePersonMapMarker(person:Partial<Person>, props:Partial<MapMarkerProps>={}): MapMarkerProps | null {
    if(!person) {
        return null
    }
    if (!person.address?.latitude || !person.address?.longitude) {
        return null
    }
    var markerClass = 'marker'
    if(person.employee) {
        markerClass = 'employee-marker'
    }
    if(person.customer) {
        markerClass = 'customer-marker'
    }

    return {
        id: person.id,
        geoposition: [
            person.address?.longitude,
            person.address?.latitude
        ],
        markerClass: `${markerClass} ${props.markerClass}`
    }
}

const usePersonUtility = () => {
    const isActive = (person:Person):boolean => {
        if(!person) {
            return false
        }
        var entry = person.entry ? moment(person.entry) : moment()
        var exit = person.exit_date ? moment(person.exit_date) : moment()
        return moment().isBetween(entry, exit, 'day', '[]')
    }

    const getAge = (person:Person):number => {
        return person ? moment().diff(person.birthday, 'years') : -1
    }

    const isUnstaffedCustomer =  (person:Person):boolean => {
        return isActive(person) && person.customer && person.customer.assignments?.length === 0
    }

    const isUnderusedEmployee = (person: Person): boolean => {
        if(person.id === 54) {
            console.log(person.employee?.assignments)
        }

        return (
            isActive(person) && 
            person.employee?.assignments?.length === 0
        )
    }

    const flaggedForDeletion = (person:Person) : boolean => {
        return person && moment().add(1, 'day').isSameOrAfter(person.deleteAt, 'day')
    }

    return {
        isActive,
        getAge,
        isUnstaffedCustomer,
        isUnderusedEmployee,
        flaggedForDeletion
    }
}

export default usePersonUtility