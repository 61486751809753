import { useInput } from "hooks/input";
import {useContext, useEffect } from "react";
import logo from './../../../assets/logo.png';
import { AuthContext } from "Login/AuthContext";
import TextInput from 'components/input/TextInput';
import SubmitButton from '../../../Forms/SubmitButton';
import Row from "components/Row";
import Card from "components/Card";
import Spacer from "components/Spacer/Spacer";
import { FormGroup } from "components/form/FormComponents";
import Column from "components/Column";
import styled from 'styled-components'
import Label from "components/labels/Label";

const LogoContainer = styled.div`
    width: 100px;
    height: 100px;
    display: flex;
    justify-self: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    border: 1px solid lightgray;
    border-radius: 50%;
    top: -80px;
    background-color: white;
    padding: 1.5em;
`

const Logo = styled.img`
    flex-grow: 1;
`

const View = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ce006933;
`

const StyledCard = styled(Card)`
    position: relative;
    padding-top: 5em;
`

export default function LoginView(props) {
    const { value: email, bind: bindEmail } = useInput("");
    const { value: password, bind: bindPassword } = useInput("");

    const { state: {errorMessage }, signin, tryLocalSignin } = useContext(AuthContext)

    useEffect(() => {
        tryLocalSignin()
    }, [tryLocalSignin])

    const login = (e) => {
        signin({
            email, password
        })
    }

    return (
        <View>
            <Column width='20em'>
                <StyledCard>
                    <Spacer horizontal='medium' vertical='large'>
                        <LogoContainer>
                            <Logo src={logo} alt="logo" />
                        </LogoContainer>
                        {(errorMessage !== "") ? (<Label error>{errorMessage}</Label>) : ""}
                        <FormGroup>
                            <TextInput label='Email' type="text" {...bindEmail} />
                        </FormGroup>
                        <FormGroup>
                            <TextInput label='Passwort' type="password" {...bindPassword} />
                        </FormGroup>
                        <Spacer vertical='medium'>
                            <Row>
                                <SubmitButton onClick={login} grow>{'Login'}</SubmitButton>
                            </Row>
                        </Spacer>
                    </Spacer>
                </StyledCard>
            </Column>
        </View>
    )
}