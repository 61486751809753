
import { AbsenceContext } from 'Absences/AbsenceContext';
import AbsenceListItem from 'Absences/AbsenceListItem';
import { EventContext } from 'Events/EventContext';
import EventListItem from 'Events/EventListItem';
import EventUtility from 'Events/EventUtility';
import { dateInPeriod } from 'Time/periodUtility';
import Column from 'components/Column';
import IconWrapper from 'components/Icons/IconWrapper';
import ListView from 'components/ListView/ListView';
import Row from 'components/Row';
import Spacer from 'components/Spacer/Spacer';
import Label from 'components/labels/Label';
import DateLabel from 'components/labels/DateLabel';
import { ModalContext } from 'components/modal/ModalContext';
import { useContext, useMemo } from 'react';
import { FaAngleDoubleRight, FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import moment from 'moment';
import { usePerson } from 'Persons/PersonApi';

const Sidebar = styled.div`
    background-color: white;
    position: absolute;
    border-left: 1px solid rgb(219, 219, 219);
    right: ${props => props.visible ? 0 : '-100%'};
    top: 0;
    bottom: 0;
    z-index: 10;
    min-width: 400px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 2px;
    transition: 0.3s;
`

const BackgroundClickHandler = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    display: ${props => props.visible ? 'block' : 'none'};
`

const ScheduleDetails = ({ date, employeeId, visible, hide }) => {

    const { absenceModal, eventModal } = useContext(ModalContext)
    const employee = usePerson(employeeId)

    const { data: absences } = useContext(AbsenceContext)
    const { data: events } = useContext(EventContext)

    const relevantEvents = useMemo(() => {
        return events?.filter(event => (
            EventUtility.includesDate(event, date) &&
            (
                event?.employee_id === employeeId ||
                (
                    event?.employee_id === null &&
                    event?.creator_id === employeeId
                )
            )
        ))
    }, [events, employeeId, date])

    const relevantAbsences = useMemo(() => {
        return absences.filter(absence => (
            absence?.employee_id === employeeId && 
            dateInPeriod(date, absence.begin_date, absence.end_date)
        ))
    }, [absences, employeeId, date])

    const createAbsence = () => {
        absenceModal.select({
            employee_id: employeeId,
            begin_date: date,
            end_date: date
        })
    }

    const createEvent = () => {
        eventModal.select({
            date: moment(date).format('YYYY-MM-DD'),
            creator_id: employeeId,
            employee_id: employeeId
        })
    }

    return (
        <>
            <BackgroundClickHandler visible={visible} onClick={hide} />
            <Sidebar visible={visible}>
                <Spacer size='medium'>
                    <Column gap='16px'>
                        <Row>
                            <IconWrapper onClick={hide}>
                                <FaAngleDoubleRight size={20}/>
                            </IconWrapper>
                        </Row>
                        <div>
                            <DateLabel format='dddd, DD.MM.YYYY' value={date}/>
                            <Label large bold>{`${employee.data?.first_name} ${employee.data?.last_name}`}</Label>
                        </div>
                        <div>
                            <Row vCentered>
                                <Column grow>
                                    <Label large>Abwesenheiten</Label>
                                </Column>
                                <IconWrapper onClick={createAbsence}>
                                    <FaPlus size={18} />
                                </IconWrapper>
                            </Row>
                            <ListView
                                data={relevantAbsences}
                                listItem={(absence) => (
                                    <AbsenceListItem
                                        absence={absence}
                                        onClick={() => absenceModal.select(absence)}
                                    />
                                )}
                                placeholder='-'
                                Separator={() => null}
                            />
                        </div>
                        <div>
                            <Row vCentered>
                                <Column grow>
                                    <Label large>Einsätze</Label>
                                </Column>
                                <IconWrapper onClick={createEvent}>
                                    <FaPlus size={18} />
                                </IconWrapper>
                            </Row>
                            <ListView
                                data={relevantEvents}
                                listItem={(event) => (
                                    <EventListItem
                                        periodFormat='HH:mm'
                                        event={event}
                                        showEmployee={false}
                                        onClick={() => eventModal.select(event)}
                                        hideEmployee={true}
                                    />
                                )}
                                placeholder='-'
                                Separator={() => null}
                            />
                        </div>                   
                    </Column>
                </Spacer>
            </Sidebar>
        </>
    )
}

export default ScheduleDetails