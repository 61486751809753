
import useSignalingActions from 'Signals/useSignalingActions';
import ameli from 'Api/ameli';
import { useQuery } from '@tanstack/react-query';

const channel = ['CustomerNotes']

const actions = (customerId) => ({
    'create': data => ameli.post(`customers/${customerId}/notes`, data ),
    'delete': id => ameli.delete(`/notes/${id}`),
    'update': data => ameli.patch(`/notes/${data.id}`, data)
})

const useCustomerNoteActions = (customerId) => {
    return useSignalingActions(actions(customerId), channel)
}

export default function useCustomerNotes(customerId) {

    return useQuery({
        queryKey: ['notes', 'customers', customerId],
        queryFn: () => {
            return ameli.get(`customers/${customerId}/notes`).then(res => res.data)
        },
        enabled: !isNaN(customerId)
    })
}

export { useCustomerNoteActions }