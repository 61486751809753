import Table from "components/table/table";
import MonthSelect from "components/Time/MonthSelect";
import YearSelect from "components/Time/YearSelect";
import { useInput } from "hooks/input";
import moment from "moment";
import { useMemo } from 'react';
import Spacer from "components/Spacer/Spacer";
import Row from "components/Row";
import TextInput from '../../../components/input/TextInput';
import Button from "components/Button";
import useModal from "components/modal/useModal";
import Tile from "components/Tiles/Tile";
import { useOutletContext } from "react-router-dom";
import CustomerHeaderBar from "Customer/CustomerHeaderBar";
import EventModal from "Events/EventModal";
import useEventColumns from "Events/useEventColumns";
import { PersonOutletContextType } from "Persons/PersonOutlet";
import Event from "Events/Event";
import { useEvents } from "Events/EventApi";

export default function CustomerOperations() {

    const { person } = useOutletContext<PersonOutletContextType>()
    const { value: year, bind: bindYear } = useInput(moment().year());
    const { value: month, bind: bindMonth } = useInput(moment().month()+1);
    const { value: filter, bind: bindFilter } = useInput("");
    const columns = useEventColumns({'hidden': ['Kunde']});
    const modal = useModal<Event>()

    const getPeriod = (year, month) => {
        if(year && month) {
            return ([
                moment().year(year).month(month-1).startOf('month').startOf('day').format('YYYY-MM-DD HH:mm'),
                moment().year(year).month(month - 1).endOf('month').endOf('day').format('YYYY-MM-DD HH:mm')
            ])
        }
        else if(year) {
            return ([
                moment().year(year).startOf('year').startOf('day').format('YYYY-MM-DD HH:mm'),
                moment().year(year).endOf('year').endOf('day').format('YYYY-MM-DD HH:mm')
            ])
        }
        return [undefined, undefined]
    }

    const params = useMemo(() => {
        const [periodStart, periodEnd] = getPeriod(year, month)

        return { 
            periodStart,
            periodEnd,
            customer_id: person.id 
        }
    }, [person, year, month])
    const { data } = useEvents(params)


    const openCreateModal = () => {
        modal.select({
            customer_id: person.id,
            date: moment().format('yyyy-MM-DD'),
            title: 'Einsatz',
            duration: 0,
            kilometer: 0,
            supplement_id: 1,
            deselected_budgets: [],
            confirmed: true
        })        
    }

    return (
        <>
            <CustomerHeaderBar/>
            <Spacer size='medium' shrink>
                <Tile>
                    <Spacer horizontal='medium'>
                        <Row vCentered>
                            <TextInput size={20} label="Suchen" {...bindFilter}/>
                            <MonthSelect label='Monat' value={month} {...bindMonth}></MonthSelect>
                            <YearSelect label='Jahr' value={year} {...bindYear}></YearSelect>
                            <Button onClick={openCreateModal}>Einsatzerfassung</Button>
                        </Row>
                    </Spacer>
                    <Table
                        columns={columns}
                        onClick={modal.select}
                        filter={filter}
                        data={data}
                    />
                </Tile>
            </Spacer>
            <EventModal {...modal}/>
        </>
    );
}