import Row from "components/Row";
import Spacer from "components/Spacer/Spacer";
import Tile, {  TileHeader, TileTitle } from "components/Tiles/Tile";
import MonthSelect from "components/Time/MonthSelect";
import YearSelect from "components/Time/YearSelect";
import TextInput from "components/input/TextInput";
import Table from "components/table/table";
import { useInput } from "hooks/input";
import moment from "moment";
import useBills from "./useBills";
import { useUnpaidTableColumns } from "./useUnpaidBillColumns";
import { useContext } from "react";
import { ModalContext } from "components/modal/ModalContext";

export default function UnpaidBillsTile() {

    const { value: filter, bind: bindFilter } = useInput("");
    const { columns } = useUnpaidTableColumns();
    const { value: year, bind: bindYear } = useInput(moment().year());
    const { value: month, bind: bindMonth } = useInput(null);

    const { data } = useBills({
        month,
        year,
        unsettled: true
    })
    
    const { billModal } = useContext(ModalContext)

    return (
        <Tile
            title="Offene Rechnungen"
        >
            <Spacer horizontal='medium' vertical='small'>
                <Row>
                    <TextInput label='Filtern' size={30} {...bindFilter} placeholder='Name, Vers-Nr., Krankenkasse ...' />
                    <MonthSelect label='Monat' value={month} {...bindMonth}></MonthSelect>
                    <YearSelect label='Jahr' value={year} {...bindYear}></YearSelect>
                </Row>
            </Spacer>
            <Table
                data={data || []}
                filter={filter}
                columns={columns}
                onClick={billModal.select}
            />
        </Tile>
    )
}