import Row from "components/Row";
import ScrollView from "components/ScrollView";
import Spacer from "components/Spacer/Spacer";
import CustomerOperationNoteTile from "./CustomerOperationNoteTile";
import ClinicalPictureTile from "../ClinicalPictures/ClinicalPictureTile";
import CustomerBudgetSection from "./CustomerBudgetTile";
import Column from "components/Column";
import { useOutletContext } from "react-router-dom";
import CustomerHeaderBar from "./CustomerHeaderBar";
import CustomerNoteTile from "../CustomerNotes/CustomerNoteTile";
import CustomerTile from "./CustomerTile";
import CustomerCalendar from "Persons/PersonCalendar";
import Tile from "components/Tiles/Tile";
import ConditionalView from "components/Decorator/ConditionalView";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ameli from "Api/ameli";
import { useClinicalPicture } from "ClinicalPictures/ClinicalPicturesApi";
import { useCustomer } from "./Api/CustomerApi";
import PersonTile from "Persons/PersonTile";
import AddComponentButton from "components/AddComponentButton";
import { FaHeartbeat } from "react-icons/fa";
import { PersonOutletContextType } from "Persons/PersonOutlet";
import CustomerServiceTile from "CustomerServices/CustomerServiceTile";
import PersonLocationTile from "Persons/PersonLocationTile";

export default function CustomerInfo() {

    const { person } = useOutletContext<PersonOutletContextType>()
    const customer = useCustomer(person?.id)
    const clinicalPicture = useClinicalPicture(person?.clinical_picture_id)
    const queryClient = useQueryClient()

    const createClinicalPicture = useMutation({
        mutationFn: () => ameli.post(`persons/${person?.id}/clinical_picture`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['persons']})
        }
    })

    if (!person) {
        return null;
    }

    return (
        <>
            <CustomerHeaderBar/>
            <ScrollView>
                <Spacer size='medium'>
                    <Row gap='1em'>
                        <Column gap='0.75em'>
                            <PersonTile person={person}/>
                            <CustomerTile customer={customer.data}/>
                            <CustomerServiceTile customerId={person?.id} />
                            <CustomerNoteTile customer={customer.data}/>
                        </Column>
                        <Column grow minWidth='45em' gap='0.75em'>
                            <PersonLocationTile person={person}/>
                            <CustomerBudgetSection customer={customer.data} />
                            <Tile>
                                <CustomerCalendar
                                    person={person}
                                    options={{
                                        hideCustomer: true
                                    }}
                                />
                            </Tile>
                            <ClinicalPictureTile clinicalPicture={clinicalPicture.data}/>
                            <ConditionalView condition={clinicalPicture.data == null}>
                                <AddComponentButton 
                                    text='Krankengeschichte hinzufügen' 
                                    Icon={<FaHeartbeat/>}
                                    onClick={createClinicalPicture.mutate}
                                />
                            </ConditionalView>
                            <CustomerOperationNoteTile customerId={person.id}/>
                            { /*
                            <CustomerOperationTaskTile customerId={person.id}/>       
                        
                            */}
                        </Column>
                    </Row>   
                </Spacer>
            </ScrollView>          
        </>
    )
}