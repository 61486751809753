import TileSection from "components/TileSection"
import ContactPersonList from "ContactPersons/ContactPersonList";
import ContactPersonModal from "ContactPersons/ContactPersonModal";
import Spacer from "components/Spacer/Spacer";
import Tile from "components/Tiles/Tile";
import useModal from "components/modal/useModal";
import { useMemo } from "react";
import useContactPersons, { useContactPersonActions } from "ContactPersons/useContactPersons";
import Customer from "./Customer";
import Column from "components/Column";
import CustomerModal from "./CustomerModal";
import { FaPlus } from "react-icons/fa";
import Label from "components/labels/Label";
import IconWrapper from "components/Icons/IconWrapper";
import Theme from "Theming/Theme";
import { TagProps } from "Tags/Tag";

interface Props {
    customer:Customer
}

const CustomerTile = ({ customer } : Props) => {

    const customerModal = useModal<Customer>()
    const contactPersonParams = useMemo(() => ({ customer_id: customer?.person_id || -1 }), [customer])      
    const contactPersonModal = useModal()
    const { data: contactPersons } = useContactPersons(contactPersonParams)
    const dispatchContactPersonAction = useContactPersonActions()

    const tags:TagProps[] = useMemo(() => {
        var result = []
        if (customer?.state === null) {
            result.push({
                text: 'Interessent'
            })
        }
        if (customer?.state === false) {
            result.push({
                text: 'Ehemalig'
            })
        }
        return result
    }, [customer])

    return (
        <>
            <CustomerModal {...customerModal} isUpdate={true}/>
            <Tile
                title='Kundendaten'
                actions={[
                    {
                        icon: 'fa/FaPen',
                        execute:() => customerModal.select(customer)
                    }
                ]}
                tags={tags}
            >
                <Spacer horizontal='medium'>
                    <Column gap='1em'>
                        <div>
                            <TileSection label='Selbstzahler'>
                                <Label>{customer?.direct_payer ? "Ja" : "Nein"}</Label>
                            </TileSection>
                            <TileSection label='BL erneuern'>
                                <Label>{customer?.has_bl ? "Ja" : "Nein"}</Label>
                            </TileSection>
                        </div>
                        <div>
                            <TileSection label='Kontaktpersonen'>
                                <ContactPersonModal {...contactPersonModal} dispatchAction={dispatchContactPersonAction.dispatch} />
                                <ContactPersonList data={contactPersons} onSelect={contactPersonModal.select} />
                                <IconWrapper
                                    size='18px'
                                    color={Theme.subtleColor}
                                    onClick={() => contactPersonModal.select({ customer_id: customer.person_id })}
                                >
                                    <FaPlus/>
                                </IconWrapper>
                            </TileSection>
                        </div>
                    </Column>
                
                </Spacer>
            </Tile>
        </>
    )
}

export default CustomerTile
