import { TitleLabel } from "components/labels/Label"
import styled from 'styled-components'
import Spacer from "components/Spacer/Spacer"
import Row from "components/Row"
import Button from "components/Button"
import { useNavigate } from 'react-router-dom';
import DefaultNavigationTab from "components/tabs/DefaultNavigationTab"
import TabRow from "components/tabs/TabRow"
import Person from "Persons/Person"
import usePersonUtility from "Persons/usePersonUtility"
import { usePersonDelete } from "Persons/PersonApi"
import Column from "components/Column"
import FlexFill from "components/FlexFill"

const Header = styled.div`
    padding-top: 0.5em;
    background-color: white;
    display: flex;
    border-bottom: 1px solid rgb(219, 219, 219);
`

interface Props {
    person:Person
}

const EmployeeHeaderBar = ({ person }:Props) => {

    const navigate = useNavigate()
    const personUtility = usePersonUtility()
    const title = `${person?.title?.title_label} ${person.first_name} ${person.last_name}`
    const archived = !personUtility.isActive(person)
    const deletePerson = usePersonDelete()
 
    const deleteEmployee = () => {
        deletePerson.mutate(person?.id, {
            onSuccess: () => {
                navigate('../')
            }
        })
    }

    return (
        <>
            <Header>
                <Column>
                    <Spacer size='medium'>
                        <Row vCentered>
                            <TitleLabel>{title}</TitleLabel>
                            <Spacer horizontal='medium'>
                                <Row gap="4px">
                                    {
                                        archived ?
                                            <Button secondary onClick={deleteEmployee}>Löschen</Button>
                                            :
                                            null
                                    }
                                </Row>
                            </Spacer>
                        </Row>
                    </Spacer>
                    <FlexFill />
                    <TabRow>
                        <DefaultNavigationTab to='/Employees' label='Informationen' />
                        <DefaultNavigationTab to='/Employees/Einsaetze' label='Einsätze' />
                        <DefaultNavigationTab to='/Employees/Dokumente' label='Dokumente' />
                        <DefaultNavigationTab to='/Employees/Abwesenheiten' label='Abwesenheiten' />
                    </TabRow>
                </Column>
            </Header>
        </>
    )
}

export default EmployeeHeaderBar