import styled from 'styled-components';
import Theme from 'Theming/Theme';
import Action from '../../Common/Actions/Action';
import ClickableOpacity from 'components/Decorator/ClickableOpacity';
import Icon from 'components/Icons/Icon';

const Container = styled.div`
    display: flex;
    gap: 0.1em;
`


interface ActionIconsProps {
    actions?: Action[]
    color?: string,
    size?: string
}

export default function ActionIcons({ 
    actions,
    color=Theme.primaryColor,
    size
}: ActionIconsProps) {

    if(!actions || actions.length === 0) {
        return null
    }

    return (
        <Container>
            {actions?.map(({ color:iconColor, icon, execute }, index) => (
                <ClickableOpacity key={index} onClick={execute}>
                    <Icon
                        color={iconColor || color}
                        size={size}
                        path={icon}
                    />
                </ClickableOpacity>
            ))}
        </Container>
    )
}